<template>
  <v-sheet class="pa-0 ma-0" color="white">
    <br />
    <v-list-item class="mt-0">
      <template v-slot:prepend>
        <v-img
          :src="bot_logo_src"
          :width="30"
          alt="Beauty360 recommend"
        ></v-img>
      </template>
      <p class="text-h5 mx-2" style="font-weight: bold">
        {{ $t("homeview.inspire_your_salon") }}
      </p>
    </v-list-item>

    <v-row no-gutters warp justify="center" class="d-flex">
      <v-col v-for="shop in shops" :key="shop" cols="12" sm="4">
        <ShopCardWithAI
          :shopObjects="shop"
          @showThumbImage="showThumbImageEmitEvent"
        />
      </v-col>
    </v-row>
  </v-sheet>

  <v-dialog
    v-model="showThumbImage"
    :max-width="isMobileDevice.value ? '90%' : '80%'"
  >
    <Carousel :starting-image="0" :images="shopPictures" />
  </v-dialog>

  <v-row>
    <v-col class="text-center">
      <v-btn
        width="40%"
        elevation="0"
        class="ma-6 border"
        height="50px"
        :ripple="false"
        rounded="pill"
        @click="showMoreOnClick"
        >{{ $t("homeview.show_more") }}</v-btn
      >
    </v-col>
  </v-row>
</template>

<script scoped>
import ShopCardWithAI from "@/components/ShopCardWithAI.vue";
import Carousel from "@/uitls/Carousel.vue";
import Constants from "@/uitls/Constants";
import Uitls from "@/uitls/Uitls";

export default {
  components: {
    ShopCardWithAI,
    Carousel,
  },

  props: ["showMoreBtn"],

  data() {
    return {
      bot_logo_src: require("@/assets/img/robot_120.png"),
      shops: [],
      showThumbImage: false,
      shopPictures: [],
    };
  },

  async mounted() {
    const params = {
      product_id: Constants.BEAUTY_360_PRODUCT_ID,
      page: 1,
      per_page: 3,
      sort: "id",
    };

    await this.$store
      .dispatch("shopStore/fetchShops", params)
      .then((response) => {
        const shopData = [];

        response.data.forEach((shop) => {
          if (shop.status) {
            var shop_img_small = Constants.NO_IMAGE;
            if (shop.pictures.length > 0) {
              shop_img_small = shop.pictures[0].picture_url_small;
            }
            shopData.push(Uitls.setShopDetails(shop, shop_img_small));
          }
        });
        this.shops = shopData;
      })
      .catch((error) => {
        console.error(error);
      });
  },

  methods: {
    showMoreOnClick() {
      this.$router.push({
        name: "SearchResult",
      });
    },

    showThumbImageEmitEvent(value) {
      const result = this.shops.find(({ shop_id }) => shop_id === value);

      if (result) {
        this.showThumbImage = true;

        this.shopPictures = [];

        for (let i = 0; i < result.shop_img_list.length; i++) {
          this.shopPictures.push({
            id: i,
            big: result.shop_img_list[i].picture_url,
            thumb: result.shop_img_list[i].picture_url_small,
          });
        }
      }
    },
  },
};
</script>

<style></style>