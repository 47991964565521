<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    color="white"
    max-width="1200"
    rounded="lg"
    width="100%"
    elevation="0"
  >
    <v-sheet class="mt-0" v-if="images.length > 0">
      <Carousel :starting-image="0" :images="images" />
    </v-sheet>

    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="9">
          <v-btn
            block
            prepend-icon="mdi-store"
            color="blue"
            variant="outlined"
            class="text-body-1"
            style="font-weight: bold"
            :ripple="false"
            @click="onClickShopDetail"
          >
            {{ shopData.name }}
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>
        <v-col cols="1"></v-col>
        <v-btn
          icon="mdi-heart"
          color="blue"
          elevation="0"
          :ripple="false"
          size="x-small"
          :variant="this.isLikedFavorite ? 'tonal' : 'outlined'"
          @click="favoriteOnClick"
        ></v-btn>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="12">
          <div class="mx-0">
            <v-icon
              icon="mdi-map-marker"
              size="x-small"
              color="black"
              class="mx-0"
            />
            <span class="mx-2 text-black" style="font-size: 10px">
              {{ shopDetail.address }}
            </span>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-divider></v-divider>

    <v-col class="mt-0">
      <p class="mt-4 text-h6" style="font-weight: bold">
        <v-icon icon="mdi-sale" size="24" color="red" class="mt-0"></v-icon>
        {{ crowdData.title }}
      </p>
      <div class="text-body-2 mt-2" style="font-weight: normal">
        {{ crowdData.description }}
      </div>
    </v-col>

    <div class="mt-6">
      <div>
        <v-row class="pa-2">
          <v-col
            cols="12"
            md="6"
            sm="12"
            v-for="item in crowdfundingItems"
            :key="item"
          >
            <VoucherDiscountCell
              :item="item"
              :showAllItem="true"
              :isUsed="isUsedVoucher(item)"
              @voucherCellCallBack="voucherCellCallBack"
            >
            </VoucherDiscountCell>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-col>
      <div class="mt-4 mx-2">
        <p class="text-h6 mt-6 pa-2" style="font-weight: bold">
          <v-icon
            icon="mdi-help-circle-outline"
            color="green"
            class="mt-0 mx-2"
            size="x-small"
          ></v-icon>
          {{ $t("crowdfunding.howTouse") }}
        </p>
        <v-divider></v-divider>
        <div>
          <div v-snip="{ lines: 2 }" style="font-weight: normal" class="ma-2">
            <p class="text-body-1">{{ $t("crowdfunding.voucher_howto_0") }}</p>
          </div>
          <v-btn variant="text" @click="howtoOnClick()">
            {{ $t("homeview.show_more") }}
          </v-btn>
        </div>

        <p class="text-h6 mt-6 pa-2" style="font-weight: bold">
          <v-icon
            icon="mdi-alert-circle-outline"
            color="red"
            class="mt-0 mx-2"
            size="x-small"
          ></v-icon>
          {{ $t("crowdfunding.terms_policy") }}
        </p>
        <v-divider></v-divider>
        <div>
          <div v-snip="{ lines: 2 }" style="font-weight: normal" class="ma-2">
            <p class="text-body-1">{{ $t("crowdfunding.voucher_term_1") }}</p>
          </div>
          <v-btn variant="text" @click="termOnClick()">
            {{ $t("homeview.show_more") }}
          </v-btn>
        </div>
      </div>
    </v-col>

    <ShopMenuList
      class="mt-6"
      v-if="shopData.detail != null"
      :shopObjects="shopData"
    />

    <br />
    <br />
    <CrowdfundingsList
      :pageTitle="`crowdfunding.similar`"
      :isCrowdfoundingDetail="true"
      :showItemCount="6"
    />
  </v-sheet>

  <v-bottom-sheet v-model="bottomSheet" inset>
    <v-card :title="bottomSheetTitle">
      <v-card-text>
        <span style="white-space: pre-line" class="text-body-2">
          {{ bottomSheetDesc }}
        </span>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
  <VSonner position="top-center" />

  <v-dialog v-model="loadingDialog" persistent width="300">
    <v-sheet height="190" class="text-center">
      <v-progress-circular
        :indeterminate="loadingDialog"
        :size="100"
        color="blue"
        class="mt-12"
      >
        {{ $t("homeview.loading") }}
      </v-progress-circular>
    </v-sheet>
  </v-dialog>
</template>

<script>
import Carousel from "@/uitls/Carousel.vue";
import CrowdfundingsList from "@/components/CrowdfundingsList.vue";
import { mapGetters } from "vuex";
import Constants from "@/uitls/Constants";
import { VSonner, toast } from "vuetify-sonner";
import ShopMenuList from "@/components/ShopMenuList.vue";
import VoucherDiscountCell from "@/components/VoucherDiscountCell.vue";

export default {
  components: {
    Carousel,
    CrowdfundingsList,
    VSonner,
    ShopMenuList,
    VoucherDiscountCell,
  },

  props: ["crowdfundingId"],

  computed: {
    ...mapGetters({
      isAuthenticated: "authStore/idToken",
      userInfo: "userStore/userInfo",
    }),
  },

  watch: {
    isAuthenticated(to, from) {},

    async "$route.params.crowdfundingId"(newVal, oldVal) {
      if (newVal !== oldVal) {
        window.scrollTo(0, 0);
        location.reload();
      }
    },
  },

  data() {
    return {
      debugMode: true,
      loadingDialog: true,
      crowdData: {},
      favorites: [],
      isLikedFavorite: false,
      crowdfundingItems: [],
      shopData: {},
      shopDetail: {},
      images: [],
      userVoucherList: [],
      bottomSheet: false,
      bottomSheetTitle: null,
      bottomSheetDesc: null,
    };
  },

  async beforeMount() {
    if (this.isAuthenticated) {
      this.fetchMypageFavorites();
    }
  },

  async mounted() {
    window.scrollTo(0, 0);
    await this.getCorwdfundingData(this.$route.params.crowdfundingId);
    await this.getUserVoucherList();
    this.loadingDialog = false;
  },

  methods: {
    async getCorwdfundingData(id) {
      console.log("getCorwdfundingData " + id);
      await this.$store
        .dispatch("crowdStore/fetchCrowd", id)
        .then(async (response) => {
          if (response) {
            this.crowdData = response;
            if (this.crowdData.crowdfunding_items) {
              this.crowdfundingItems = this.crowdData.crowdfunding_items;
            }
            await this.loadShopDetail();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async loadShopDetail() {
      await this.$store
        .dispatch("shopStore/fetchShop", this.crowdData.shop_id)
        .then((response) => {
          if (response) {
            this.shopData = response;
            if (this.shopData.detail) {
              this.shopDetail = this.shopData.detail;
            }
            this.setBannerImage();
          }
        });
    },

    setBannerImage() {
      for (let index = 0; index < this.shopData.pictures.length; index++) {
        const element = this.shopData.pictures[index];
        this.images.push({
          id: index,
          big: element.picture_url,
          thumb: element.picture_url_small,
        });
      }

      for (let index2 = 0; index2 < this.crowdfundingItems.length; index2++) {
        const element2 = this.crowdfundingItems[index2];

        this.images.push({
          id: index2 + 1,
          big: this.getImageUrl(element2.picture_path),
          thumb: this.getImageUrl(element2.picture_path),
        });
      }
    },

    getImageUrl(imagePath) {
      return new URL(Constants.IMAGE_URL + imagePath).href;
    },

    async fetchMypageFavorites() {
      if (this.isAuthenticated) {
        const params = {
          id: this.userInfo.id,
          target_type: Constants.FAVORITE_TYPE_SHOP,
          page: 1,
          per_page: 300,
        };

        await this.$store
          .dispatch("userStore/fetchFavorites", params)
          .then((response) => {
            this.favorites = response.data;
            this.chceckHasFavorite();
          })
          .catch((error) => {});
      }
    },

    chceckHasFavorite() {
      var tmp = this.favorites.filter(
        (e) => e.target_id == this.crowdfundingId
      );
      if (tmp.length > 0) {
        this.isLikedFavorite = true;
      } else {
        this.isLikedFavorite = false;
      }
    },

    favoriteOnClick() {
      if (!this.isAuthenticated) {
        toast(this.$i18n.tc("shop.pls_login"), {
          cardProps: {
            color: "info",
            class: "my-toast",
          },
        });
        return;
      }

      const params = {
        target_type: Constants.FAVORITE_TYPE_SHOP,
        target_id: this.crowdfundingId,
        user_id: this.userInfo.id,
      };

      this.isLikedFavorite = !this.isLikedFavorite;
      if (this.isLikedFavorite) {
        this.addFavorite(params);
      } else {
        this.deleteFavorite(params);
      }
    },

    onClickShopDetail() {
      this.$router.push({
        name: "Shop",
        params: { id: this.crowdData.shop_id },
      });
    },
    addFavorite(params) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("userStore/addFavorite", params)
          .then((response) => {
            resolve(response);
            toast(this.$i18n.tc("shop.fav_added"), {
              cardProps: {
                color: "success",
                class: "my-toast",
              },
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteFavorite(params) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("userStore/deleteFavorite", params)
          .then((response) => {
            resolve(response);
            toast(this.$i18n.tc("shop.fav_remove"), {
              cardProps: {
                color: "success",
                class: "my-toast",
              },
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getUserVoucherList() {
      if (this.isAuthenticated) {
        const tobeSubmit = {
          shop_id: this.crowdData.shop_id,
        };
        await this.$store
          .dispatch("userStore/getUserVoucherList", tobeSubmit)
          .then((res) => {
            if (res) {
              this.userVoucherList = res;
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },

    async voucherCellCallBack(voucherItem) {
      if (voucherItem.amount == 0) {
        toast(this.$i18n.tc("crowdfunding.voucher_empty"), {
          cardProps: {
            color: "error",
            class: "my-toast",
          },
        });
        return;
      }

      if (!this.isAuthenticated) {
        this.$router.push({
          name: "LoginPage",
          query: {
            redirect: "/voucher/" + this.crowdfundingId,
          },
        });
        return;
      }

      try {
        this.loadingDialog = true;
        // TODO: check the target voucher is free or not
        // if (voucherItem.price > 0) {
        //   await this.purchaseVoucher(voucherItem)
        // } else {
        //   await this.getFreeVoucher(voucherItem)
        // }
        if (
          voucherItem.is_free != undefined &&
          voucherItem.is_free == 0 &&
          voucherItem.selling_price != undefined &&
          parseFloat(voucherItem.selling_price) > 0
        ) {
          await this.purchaseVoucher(voucherItem);
        } else {
          await this.getFreeVoucher(voucherItem);
        }
        // await this.getFreeVoucher(voucherItem)
      } catch (e) {
        console.error(e);
      }
      this.loadingDialog = false;
    },
    async getFreeVoucher(voucherItem) {
      const tobeSubmit = {
        crowdfunding_item_id: voucherItem.id,
      };
      await this.$store
        .dispatch("userStore/addVoucher", tobeSubmit)
        .then((res) => {
          if (res) {
            this.$router.push({
              name: "UseVoucher",
              params: {
                crowdfundingItemId: res.crowdfunding_item_id,
                shopId: this.crowdData.shop_id,
              },
            });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    async purchaseVoucher(voucherItem) {
      await this.$store
        .dispatch("userStore/getUserVoucher", voucherItem.id)
        .then((response) => {
          if (response) {
            this.$router.push({
              name: "UseVoucher",
              params: {
                crowdfundingItemId: voucherItem.id,
                shopId: this.crowdData.shop_id,
              },
            });
          } else {
            this.$router.push({
              name: "CrowdfundingsCheckout",
              params: {
                crowdfundingId: voucherItem.crowdfunding_id,
                crowdfundingItemId: voucherItem.id,
              },
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    isUsedVoucher(item) {
      if (this.userVoucherList) {
        const foundVoucher = this.userVoucherList.find(
          (voucher) => voucher.crowdfunding_item_id === item.id
        );
        if (foundVoucher) {
          return !!foundVoucher.used_at;
        }
      }
      return false;
    },

    howtoOnClick() {
      this.bottomSheet = !this.bottomSheet;
      this.bottomSheetTitle = this.$i18n.t("crowdfunding.howTouse");

      let texts = [
        this.$i18n.t("crowdfunding.voucher_howto_0"),
        this.$i18n.t("crowdfunding.voucher_howto_1"),
        this.$i18n.t("crowdfunding.voucher_howto_2"),
        this.$i18n.t("crowdfunding.voucher_howto_3"),
      ];

      this.bottomSheetDesc = texts.join("\n\n");
    },

    termOnClick() {
      this.bottomSheet = !this.bottomSheet;
      this.bottomSheetTitle = this.$i18n.t("crowdfunding.terms_policy");

      let texts = [
        this.$i18n.t("crowdfunding.voucher_term_0"),
        this.$i18n.t("crowdfunding.voucher_term_1"),
        this.$i18n.t("crowdfunding.voucher_term_2"),
        this.$i18n.t("crowdfunding.voucher_term_3"),
        this.$i18n.t("crowdfunding.voucher_term_4"),
      ];

      this.bottomSheetDesc = texts.join("\n\n");
    },
  },
};
</script>

<style scoped>
.footer {
  background: #4caf50;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 54px;
  padding: 0 30px 20px;
  box-shadow: 0 -6px 20px rgba(0, 0, 0, 0.3);
}
</style>