<template>
  <v-card
    elevation="0"
    class="pa-2 mt-6 mb-6"
    prepend-icon="mdi-account-heart"
    :title="$t('shop.share_friends')"
    subtitle=""
    :text="$t('shop.pls_contact_us')"
  >
    <v-row justify="center" no-gutters>
      <v-btn
        rounded="pill"
        elevation="0"
        width="50%"
        class="white--text"
        color="blue"
        height="40px"
        prepend-icon="mdi-tray-arrow-up"
        variant="outlined"
        :ripple="false"
        @click="snsShare"
      >
        {{ $t("shop.share") }}
      </v-btn>
    </v-row>
  </v-card>

  <SNSShareDialog
    :shopInfoObjects="shopInfoObjects"
    :openSNSShareDialog="openSNSShareDialog"
    @result="responseFromSNSDialog"
  ></SNSShareDialog>
</template>

<script scope>
import SNSShareDialog from "@/components/SNSShareDialog.vue";

export default {
  props: {
    shopInfoObjects: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    SNSShareDialog,
  },

  data() {
    return {
      openSNSShareDialog: false,
    };
  },

  methods: {
    snsShare() {
      this.openSNSShareDialog = true;
    },
    responseFromSNSDialog(obj) {
      this.openSNSShareDialog = false;
    },
  },
};
</script>

<style>
</style>