<template>
  <v-app-bar scroll-behavior="elevate" color="grey-lighten-4">
    <v-btn class="ma-0" icon="mdi-arrow-left" @click="$router.push('/settingpage')"></v-btn>
    <v-app-bar-title>{{$t("settingpage.profile")}}</v-app-bar-title>
  </v-app-bar>
  <v-main>
    <v-container fluid>
      <v-sheet
        elevation="0"
        max-width="1200"
        rounded="lg"
        width="100%"
        class="pa-0 mx-auto mt-6"
      >
        <v-row align="center" justify="center" class="mt-6">
          <v-btn
            elevation="0"
            :ripple="false"
            size="140"
            icon
            stacked
            @click="$refs.fileInput.click()"
          >
            <input
              style="display: none"
              ref="fileInput"
              accept="image/jpeg, image/png"
              type="file"
              @change="imageFromDevice"
              enctype="multipart/form-data"
            />

            <v-avatar size="140">
              <v-img :src="avatarImage" cover :lazy-src="local_avatar"></v-img>
            </v-avatar>
            <p class="text-caption text-grey">{{$t('settingpage.avatar')}}</p>
          </v-btn>
        </v-row>

        <v-text-field
          class="mt-10"
          hide-details="auto"
          :label="$t('settingpage.your_name')"
          v-model="nameNew"
          variant="underlined"
          :readonly="loading"
          prepend-icon="mdi-rename"
          clearable
          :placeholder="$t('settingpage.your_name')"
          :error-messages="v$.nameNew.$errors.map((e) => e.$message)"
          @input="v$.nameNew.$touch"
          @blur="v$.nameNew.$touch"
        ></v-text-field>

        <v-text-field
          v-model="birthdayNew"
          class="mt-6"
          :label="$t('settingpage.your_birt')"
          :placeholder="$t('settingpage.your_birt')"
          prepend-icon="mdi-cake"
          :readonly="loading"
          type="date"
          variant="underlined"
          required
          :error-messages="v$.birthdayNew.$errors.map((e) => e.$message)"
          @input="v$.birthdayNew.$touch"
          @blur="v$.birthdayNew.$touch"
        ></v-text-field>

        <div class="mt-0">
          <p class="text-caption text-grey">{{$t('settingpage.gender')}}</p>
          <v-btn-toggle
            v-model="genderToggle"
            rounded="0"
            color="blue"
            density="compact"
            group
            class="mt-2"
          >
            <v-btn rounded="xl" height="40" value="0"> {{$t('settingpage.male')}} </v-btn>
            <v-btn rounded="xl" height="40" value="1"> {{$t('settingpage.female')}} </v-btn>
            <v-btn rounded="xl" height="40" value="2"> {{$t('settingpage.private')}} </v-btn>
          </v-btn-toggle>
        </div>

        <v-row justify="center" no-gutters>
          <v-btn
            elevation="0"
            color="blue"
            width="93%"
            class="ma-6"
            height="50px"
            :ripple="false"
            rounded="pill"
            :loading="loading"
            @click="checkUpdateInfo"
          >
          {{$t('settingpage.submit')}}
          </v-btn>
        </v-row>
      </v-sheet>
    </v-container>
  </v-main>
  <VSonner position="top-center" />
</template>

<script scoped>
import { mapGetters, mapState, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { minLength, required } from "@vuelidate/validators";
import { VSonner, toast } from "vuetify-sonner";
import utils from "@/uitls/Uitls";

export default {
  components: {
    VSonner,
  },

  setup() {
    return { v$: useVuelidate() };
  },

  computed: {
    ...mapGetters({
      userInfo: "userStore/userInfo",
    }),
  },

  data: () => ({
    selectedImage: null,
    genderToggle: null,
    nameNew: null,
    birthdayNew: null,
    selectImageFile: null,
    local_avatar: require("@/assets/img/avatar.png"),
    avatarImage: null,
    loading: false,
    userPost: {
      id: null,
      profile: {
        gender: null,
        birthday: null,
      },
    },
  }),

  validations() {
    return {
      nameNew: {
        required,
        minLength: minLength(3),
      },
      birthdayNew: {
        required,
      },
    };
  },

  mounted() {
    // console.log(this.userInfo);

    this.userPost.id = this.userInfo.id;
    this.nameNew = this.userInfo.name;

    if (
      !utils.isEmpty(this.userInfo.profile) &&
      !utils.isEmpty(this.userInfo.profile.gender)
    ) {
      this.genderToggle = this.userInfo.profile.gender;
    }

    if (
      !utils.isEmpty(this.userInfo.profile) &&
      !utils.isEmpty(this.userInfo.profile.birthday)
    ) {
      this.birthdayNew = this.userInfo.profile.birthday;
    }

    if (!utils.isEmpty(this.userInfo.profile)) {
      this.avatarImage = this.userInfo.profile.picture_url_small;
    } else {
      this.avatarImage = this.local_avatar;
    }
  },

  methods: {
    imageFromDevice(e) {
      this.selectImageFile = e.target.files[0];
      if (
        this.selectImageFile.type !== "image/jpeg" &&
        this.selectImageFile.type !== "image/png"
      ) {
        alert("Only support jpeg or png image");
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(this.selectImageFile);
      reader.onload = (e) => {
        this.selectedImage = e.target.result;
        this.avatarImage = this.selectedImage;
        // console.log(this.$refs.fileInput.files[0]);
      };
    },

    async checkUpdateInfo() {
      if (!this.nameNew || !this.birthdayNew) return;
      if (this.genderToggle == null) {
        alert("Gender is required");
        return;
      }

      this.loading = true;

      this.userPost.name = this.nameNew;
      this.userPost.profile = {
        gender: this.genderToggle,
        birthday: this.birthdayNew,
      };

      const dataUpdate = {};
      dataUpdate.user = this.userPost;
      if (this.selectedImage) {
        dataUpdate.picture = this.selectImageFile;
      }

      await this.$store
        .dispatch("userStore/updateUserInfo", dataUpdate)
        .then((data) => {
          this.loading = false;
          toast("Information updated.", {
            cardProps: {
              color: "success",
              class: "my-toast",
            },
          });

          setTimeout(() => {
            this.$router.push({ name: "SettingPage" });
          }, 3000);
        })
        .catch((e) => {
          this.loading = false;
          console.error("error: ", e);
          toast("Information update faild, please try again.", {
            cardProps: {
              color: "error",
              class: "my-toast",
            },
          });
        });
    },
  },
};
</script>