<!-- https://qiita.com/mml/items/7bdffe5eb5044f861cf1 -->
<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    color="white"
    max-width="1200"
    rounded="lg"
    width="100%"
    elevation="0"
  >
    <div class="bg"></div>

    <v-card elevation="0" class="pa-4 mt-8" color="transparent">
      <h1>
        <span
          class="text-h4 text-left text-grey-darken-3"
          style="font-weight: bold"
        >
          {{ $t("homeview.find_your_salon") }}
        </span>
      </h1>

      <!-- Search -->
      <v-sheet
        height="auto"
        class="mt-12 searchbg pa-4 ma-0"
        color="white"
        rounded="lg"
      >
        <!-- AutoCount -->
        <p
          class="text-h6 text-green"
          style="font-weight: bold"
          v-if="shopCount"
        >
          <v-icon
            icon="mdi-store"
            class="mt-n1"
            color="black"
            size="small"
          ></v-icon>
          <span class="mx-1 text-black text-subtitle-2">
            {{ $t("homeview.number_ai") }}
          </span>
          <vue3-autocounter
            class="text-h5 mx-n1"
            ref="counter"
            :startAmount="0"
            :endAmount="shopCount"
            :duration="1"
            prefix=""
            suffix=""
            separator=","
            decimalSeparator="."
            :decimals="0"
            :autoinit="true"
          >
          </vue3-autocounter>
          <span class="text-caption text-black mx-2">
            ({{ currentDate() }})
          </span>
        </p>

        <!-- Search -->
        <v-row dense class="mt-4">
          <v-col cols="2" align="center">
            <v-btn
              id="topSearchBtn"
              v-if="isMobileDevice.value"
              icon="mdi-map-marker"
              elevation="0"
              color="blue-lighten-1"
              variant="text"
              :ripple="false"
              @click="areaSelectOnclick"
            >
            </v-btn>

            <v-btn
              v-else
              prepend-icon="mdi-map-marker"
              elevation="0"
              height="70%"
              color="blue-lighten-1"
              variant="text"
              :ripple="false"
              @click="areaSelectOnclick"
            >
              {{
                selectState.code
                  ? $t("prefecture." + selectState.code)
                  : $t(selectState.name)
              }}
            </v-btn>
          </v-col>

          <v-col cols="8">
            <v-text-field
              v-model="searchKeyword"
              width="100%"
              variant="outlined"
              @keydown.enter.prevent="searchSubmit(this.searchKeyword)"
              :hint="
                selectState.code
                  ? $t('prefecture.' + selectState.code)
                  : $t(selectState.name)
              "
              persistent-hint
            >
              <v-field-label
                style="font-weight: normal"
                class="text-caption mx-n2"
              >
                {{ $t("homeview.search_keyword") }}
              </v-field-label>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-btn
              class="mx-n4"
              elevation="0"
              height="4em"
              color="blue-lighten-1"
              prepend-icon="mdi-magnify"
              stacked
              :ripple="false"
              @click="searchSubmit(this.searchKeyword)"
            >
            </v-btn>
          </v-col>
        </v-row>

        <!-- Tags -->
        <v-row no-gutters warp justify="center" class="mt-4">
          <v-col>
            <v-chip-group>
              <v-chip
                v-for="tagItem in tagList"
                :key="tagItem"
                variant="text"
                class="ma-0"
                size="small"
                @click="searchSubmit(removeText(tagItem))"
                style="font-weight: bold"
              >
                #{{ $t(tagItem) }}
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
      </v-sheet>

      <!-- Menu -->
      <v-item-group
        selected-class="bg-primary"
        align="center"
        justify="center"
        class="mt-4"
      >
        <v-row>
          <v-col
            v-for="item in menuList"
            :key="item.title"
            cols="6"
            sm="3"
            md="3"
          >
            <v-btn
              v-if="item.link == 'SearchMap'"
              rounded="lg"
              height="200"
              width="250"
              elevation="1"
              color="blue-lighten-5"
              :ripple="false"
              :to="{ name: item.link, params: { address: 'Tokyo Station' } }"
            >
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-avatar
                    color="white"
                    size="100"
                    class="mt-2 pa-4"
                    :image="item.icon"
                  >
                    <v-icon
                      :icon="item.icon"
                      size="60"
                      color="blue-lighten-1"
                    ></v-icon>
                  </v-avatar>

                  <v-sheet
                    color="blue-lighten-1"
                    rounded="pill"
                    class="mx-auto mt-8"
                    height="40"
                    width="150"
                  >
                    <v-row align="center" justify="center">
                      <v-col style="font-weight: bold">
                        {{ $t(item.title) }}</v-col
                      >
                    </v-row>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-btn>

            <v-btn
              v-else-if="item.link == 'SearchFilter'"
              rounded="lg"
              height="200"
              width="250"
              elevation="1"
              color="blue-lighten-5"
              :ripple="false"
              @click="openSearchFilterDialog = true"
            >
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-avatar
                    color="white"
                    size="100"
                    class="mt-2 pa-4"
                    :image="item.icon"
                  >
                    <v-icon
                      :icon="item.icon"
                      size="60"
                      color="blue-lighten-1"
                    ></v-icon>
                  </v-avatar>

                  <v-sheet
                    color="blue-lighten-1"
                    rounded="pill"
                    class="mx-auto mt-8"
                    height="40"
                    width="150"
                  >
                    <v-row align="center" justify="center">
                      <v-col style="font-weight: bold">
                        {{ $t(item.title) }}</v-col
                      >
                    </v-row>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-btn>

            <v-btn
              v-else
              rounded="lg"
              height="200"
              width="250"
              elevation="1"
              color="blue-lighten-5"
              :ripple="false"
              :to="{ name: item.link }"
            >
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-avatar
                    color="white"
                    size="100"
                    class="mt-2 pa-4"
                    :image="item.icon"
                  >
                  </v-avatar>

                  <v-sheet
                    color="blue-lighten-1"
                    rounded="pill"
                    class="mx-auto mt-8"
                    height="40"
                    width="150"
                  >
                    <v-row align="center" justify="center">
                      <v-col style="font-weight: bold">
                        {{ $t(item.title) }}
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-btn>
          </v-col>
        </v-row>
      </v-item-group>
    </v-card>

    <v-sheet class="mt-6">
      <AIBotRecommend />
    </v-sheet>

    <!-- Promotons -->
    <v-sheet class="mt-6">
      <CrowdfundingsList
        :pageTitle="$t('homeview.special_voucher')"
        :isCrowdfoundingDetail="false"
        :showItemCount="10"
      />
    </v-sheet>

    <v-sheet class="mt-6">
      <ArticleBanner />
    </v-sheet>

    <v-sheet class="mt-8">
      <LocationShopList />
    </v-sheet>

    <v-sheet class="mt-6">
      <HairStyleAlbum />
    </v-sheet>

    <!-- Sponsor Shop -->
    <!-- <v-sheet class="mt-0">
      <v-carousel
        hide-delimiters
        cycle
        v-if="bannerList.length > 0"
        show-arrows="hover"
        :height="isMobileDevice.value ? 280 : 450"
        width="100%"
        color="bg-grey-lighten-2"
      >
        <v-carousel-item
          v-for="item in bannerList"
          :key="item"
          eager
          transition="fade-transition"
          @click="bannerOnClick(item.banner_url)"
        >
          <v-img :src="item.banner_img" height="100%" :lazy-src="local_logo">
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-sheet> -->

    <!-- Service Category-->
    <!-- <v-sheet color="grey-lighten-4  mt-12">
      <v-sheet class="ma-2 pa-2" color="grey-lighten-4">
        <p class="text-h5 mt-4" style="font-weight: bold">
          <v-icon
            icon="mdi-shape-plus"
            color="blue-darken-1"
            size="24px"
            class="mb-1"
          ></v-icon>
          {{ $t("homeview.service") }}
        </p>
      </v-sheet>

      <v-row no-gutters warp justify="left">
        <v-col v-for="item in categoryList" :key="item.name" cols="6" md="2">
          <v-card
            flat
            class="text-left ma-3"
            color="white"
            elevation="1"
            :ripple="false"
            @click="searchSubmit(item.tag)"
          >
            <v-responsive class="pt-0">
              <v-img
                class="ma-6"
                max-height="90%"
                :src="item.image"
                aspect-ratio="1.0"
              ></v-img>
              <v-card-text>
                <p class="text-h6 text-center" style="font-weight: bold">
                  {{ $t(item.name) }}
                </p>
              </v-card-text>
            </v-responsive>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet> -->

    <!-- ScrollBanner -->
    <!-- <v-sheet elevation="0">
      <div class="scrollContainer mt-2" ref="scrollContainer">
        <div class="scrollContent" ref="scrollContent">
          <v-img
            v-for="n in 4"
            :key="n"
            class="ma-2"
            max-height="100%"
            :width="isMobileDevice.value ? 320 : 480"
            src="./banner.jpg"
          ></v-img>
        </div>
      </div>
    </v-sheet> -->

    <!-- Banner -->
    <div align="center">
      <v-img
        alt="Beauty360 Campaign"
        class="mt-12"
        :src="checkBannerLanguage"
        @click="bannerClick"
        max-width="960"
      ></v-img>
    </div>

    <!-- LocationSelect -->
    <vue-bottom-sheet
      ref="myBottomSheet"
      :max-width="isMobileDevice.value ? 620 : 960"
      max-height="80%"
      transition-duration="0.25"
      :can-swipe="true"
      :overlay="true"
      v-if="isLoadedPrefecture"
    >
      <v-sheet class="ma-6">
        <p class="text-h5">{{ $t("loginpage.select_area") }}</p>
        <v-list>
          <v-list-item
            v-for="item in prefectureList"
            :key="item.text"
            :title="item.code ? $t('prefecture.' + item.code) : item.name"
            :value="item.id"
            color="primary"
            @click="selectItem(item)"
          ></v-list-item>
        </v-list>
      </v-sheet>
    </vue-bottom-sheet>

    <!-- HairCatalog -->
    <!-- <v-sheet class="mt-6">
      <HairCatalog />
    </v-sheet> -->

    <!--
    <v-sheet class="mt-6">
      <TopStyleList :showMoreBtn="`yes`" />
    </v-sheet>

    <v-sheet class="mt-6">
      <HairStyleCard />
    </v-sheet>

    
    <v-sheet class="mt-6">
      <SearchBar />
    </v-sheet> -->

    <v-sheet class="mt-12 mb-12">
      <SponsorBanner v-if="bannerList.length > 0" :itemObjects="bannerList" />
    </v-sheet>

    <SearchFilterDialog
      v-if="openSearchFilterDialog"
      :openSearchFilterDialog="openSearchFilterDialog"
      :searchFilterObjects="searchFilterObjectsDialog"
      @result="responseFromSearchFilterDialog"
      :prefectureList="prefectureList"
    ></SearchFilterDialog>
  </v-sheet>
</template>


<script>
import LocationShopList from "@/components/LocationShopList.vue";
import HairStyleCard from "@/components/HairStyleCard.vue";
import TopStyleList from "@/components/TopStyleList.vue";
import HairCatalog from "@/components/HairCatalog.vue";
import SearchBar from "@/components/SearchBar.vue";
import HairStyleAlbum from "@/components/HairStyleAlbum.vue";
import SponsorBanner from "@/components/Banner/SponsorBanner.vue";
import ArticleBanner from "@/views/Article/ArticleBanner.vue";
import LanguagePack from "../locales/LanguagePack";
import CrowdfundingsList from "@/components/CrowdfundingsList.vue";
import AIBotRecommend from "@/components/AIBotRecommend.vue";
import firebase from "@/plugins/firebase";
import utils from "@/uitls/Uitls";
import VueGeolocation from "vue-browser-geolocation";
import Uitls from "@/uitls/Uitls";
import Vue3autocounter from "vue3-autocounter";
import { event } from "vue-gtag-next";
import { useHead } from "@vueuse/head";
import { mapGetters, mapState } from "vuex";
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import SearchFilterDialog from "@/components/SearchFilterDialog.vue";

export default {
  components: {
    LocationShopList,
    HairStyleCard,
    TopStyleList,
    HairCatalog,
    SearchBar,
    HairStyleAlbum,
    SponsorBanner,
    ArticleBanner,
    CrowdfundingsList,
    AIBotRecommend,
    Vue3autocounter,
    VueBottomSheet,
    SearchFilterDialog,
  },

  i18n: {
    sharedMessages: LanguagePack,
  },

  async mounted() {
    this.setMetaInfo();
    this.shopCount = this.shopTotalCount;
    await this.getPrefectureList();
  },

  computed: {
    ...mapGetters({
      shopTotalCount: "shopStore/shopTotalCount",
    }),
    ...mapState({
      getsponsoreList: (state) => state.SponsorStore.sponsoreList,
    }),

    checkBannerLanguage() {
      switch (this.$i18n.locale) {
        case "zhCN":
          return this.banner_img_zhcn;

        default:
          return this.banner_img;
      }
    },
  },

  data() {
    return {
      bannerList: [],
      bot_logo_src: require("@/assets/img/robot_120.png"),
      local_logo: require("@/assets/img/logo120.png"),
      banner_img: require("@/assets/img/campaign.jpg"),
      banner_img_zhcn: require("@/assets/img/campaign_zhcn.jpg"),
      menuList: [
        {
          title: "homeview.search",
          icon: "/hair-salon.png",
          link: "SearchFilter",
        },
        {
          title: "homeview.new_topics",
          icon: "/magazine.png",
          link: "ArticleViewList",
        },
        { title: "homeview.map", icon: "/map.png", link: "GMapView" },
        {
          title: "homeview.voucher",
          icon: "/discounts.png",
          link: "SearchVoucherResult",
        },
      ],
      categoryList: [
        { name: "shopprice.color", tag: "color", image: "/hair-dye.png" },
        { name: "shopprice.prem", tag: "prem", image: "/hairdryer.png" },
        { name: "shopprice.cut", tag: "cut", image: "/baber-shop.png" },
        { name: "shopprice.facial", tag: "facial", image: "/facial.png" },
        {
          name: "shopprice.treatment",
          tag: "treatment",
          image: "/hair-dye2.png",
        },
        { name: "shopprice.eyelash", tag: "eyelash", image: "/eyelashes.png" },
        {
          name: "shopprice.head_spa",
          tag: "head spa",
          image: "/hot-stones.png",
        },
      ],
      shopCount: null,
      tagList: Uitls.GPTSummaryTags(),
      selectState: {
        title: "prefecture.all_area",
        id: 0,
        name: "prefecture.all_area",
      },
      searchKeyword: null,
      prefectureList: [],
      isLoadedPrefecture: false,
      openSearchFilterDialog: false,
      searchFilterObjectsDialog: {
        searchKeyword: "",
        prefecture_v2: 0,
        priceRange: [0, 1000],
        selectedTag: [],
        selectedTime: null,
      },
    };
  },

  created() {
    VueGeolocation.getLocation({
      enableHighAccuracy: false,
      timeout: Infinity,
      maximumAge: 0,
    })
      .then((coordinates) => {
        this.$cookies.set("coordinates", coordinates);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async beforeMount() {
    this.loadFireBaseRemote();
  },

  methods: {
    currentDate() {
      const current = new Date();
      const date = `${current.getDate()}.${
        current.getMonth() + 1
      }.${current.getFullYear()}`;
      return date;
    },

    bannerOnClick(url) {
      window.open(url, "_self");
    },

    async loadFireBaseRemote() {
      this.$store
        .dispatch("SponsorStore/sponsorInfo")
        .then((data) => {
          this.bannerList = this.getsponsoreList;
        })
        .catch((e) => {
          console.error("error: ", e);
        });
    },

    setMetaInfo() {
      useHead({
        title: this.$i18n.tc("homeview.find_your_salon"),
        meta: [
          {
            name: "robots",
            content: "index,follow",
          },
          {
            name: "description",
            content: this.$i18n.tc("homeview.og_desc"),
          },
          {
            property: "og:title",
            content: this.$i18n.tc("homeview.site_title"),
          },
          {
            property: "og:description",
            content: this.$i18n.tc("homeview.og_desc"),
          },
          {
            property: "og:url",
            content: "https://beauty-360.com",
          },
        ],
      });
    },

    areaSelectOnclick() {
      this.$refs.myBottomSheet.open();
    },
    selectItem(item) {
      this.selectState = item;
      this.$refs.myBottomSheet.close();
    },

    searchSubmit(value) {
      const searchFilterObjects = {
        searchKeyword: value,
        prefecture_v2: parseInt(this.selectState.id),
        priceRange: [0, 1000],
      };

      this.$router.push({
        name: "SearchResult",
        query: { query: JSON.stringify(searchFilterObjects) },
      });
    },

    responseFromSearchFilterDialog(obj) {
      this.openSearchFilterDialog = false;
      this.$router.push({
        name: "SearchResult",
        query: { query: JSON.stringify(obj) },
      });
    },

    bannerClick() {
      this.openCampaignPage();
    },

    openCampaignPage() {
      this.$router.push({
        name: "ForShopLanguageSelect",
      });
    },

    openGoogleServay() {},

    removeText(value) {
      return this.$i18n.tc(value);
    },

    async getPrefectureList() {
      const vm = this;
      vm.prefectureList = [
        {
          id: 0,
          name: "all_area",
          code: "all_area",
        },
      ];
      await vm.$store
        .dispatch("shopStore/getPrefectureByCountry", "malaysia")
        .then(async (response) => {
          if (response && response.data && response.data.length > 0) {
            response.data.forEach((v) => {
              vm.prefectureList.push({
                id: v.id,
                name: v.name,
                code: v.code,
              });
            });
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          vm.isLoadedPrefecture = true;
        });
    },
  },
};
</script>

<style scoped>
.glow {
  color: white;
  text-shadow: 0 0 10px black, 0 0 20px black, 0 0 30px black, 0 0 40px black,
    0 0 70px black, 0 0 80px black, 0 0 100px black, 0 0 150px black;
}

.autocounter {
  /* font-family: American Typewriter, serif; */
  font-weight: bold;
  font-style: italic;
  font-size: 1rem;
  text-align: left;
  color: black;
}

.scrollContainer {
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
}
.scrollContent {
  display: flex;
  flex-wrap: nowrap;
}
.bg {
  width: 100%;
  height: 26em;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;

  background: linear-gradient(
      0deg,
      rgba(250, 250, 0250, 0.1),
      rgba(255, 255, 255, 1)
    ),
    url("@/assets/img/bg.webp");

  background-size: cover;
  transform: scale(1);
}

.searchbg {
  background-color: white;
}
</style>


<!-- https://storage.googleapis.com/beauty360-dev/toppage/bg-blue.png -->