<template>
  <v-row justify="center">
    <v-dialog class="ma-3" v-model="this.openSNSShareDialog" max-width="960">
      <v-sheet
        class="pa-0 ma-0 mx-auto"
        color="white"
        rounded="lg"
        width="100%"
        elevation="0"
        justify="center"
      >
        <v-toolbar dark color="blue" height="48">
          <p class="mx-6">{{ $t("shop.share") }}</p>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-4"
            elevation="0"
            density="comfortable"
            icon="mdi-close"
            :ripple="false"
            @click="closeDialogWithResult"
          ></v-btn>
        </v-toolbar>

        <v-row justify="center" no-gutters>
          <ShareNetwork
            network="facebook"
            :url="shopUrl"
            :title="title"
            :description="share_desc"
            :quote="quote"
            hashtags="Beauty 360, Malaysia Beauty salon"
          >
            <v-btn
              prepend-icon="mdi-facebook"
              stacked
              variant="text"
              color="blue"
              ><p class="text-caption">Facebook</p></v-btn
            >
          </ShareNetwork>

          <ShareNetwork
            network="Messenger"
            :url="shopUrl"
            :title="title"
            :description="share_desc"
            :quote="quote"
            hashtags="Beauty 360, Malaysia Beauty salon"
          >
            <v-btn
              prepend-icon="mdi-facebook-messenger"
              variant="text"
              color="blue"
              stacked
              ><p class="text-caption">Messenger</p></v-btn
            >
          </ShareNetwork>

          <ShareNetwork
            network="Twitter"
            :url="shopUrl"
            :title="title"
            :description="share_desc"
            :quote="quote"
            hashtags="Beauty 360, Beauty salon, Hair Catalog"
          >
            <v-btn
              prepend-icon="mdi-twitter"
              variant="text"
              color="blue"
              stacked
              ><p class="text-caption">Twitter</p></v-btn
            >
          </ShareNetwork>

          <ShareNetwork
            network="SMS"
            :url="shopUrl"
            :title="title"
            :description="share_desc"
            :quote="quote"
            hashtags="Beauty 360, Malaysia Beauty salon"
          >
            <v-btn
              prepend-icon="mdi-message-processing"
              size="large"
              variant="text"
              stacked
              color="blue"
              ><p class="text-caption">SMS</p></v-btn
            >
          </ShareNetwork>

          <ShareNetwork
            network="email"
            :url="shopUrl"
            :title="title"
            :description="share_desc"
            :quote="quote"
            hashtags="Beauty 360, Malaysia Beauty salon"
          >
            <v-btn prepend-icon="mdi-email" variant="text" stacked color="blue"
              ><p class="text-caption">Email</p></v-btn
            >
          </ShareNetwork>

          <ShareNetwork
            network="WhatsApp"
            :url="shopUrl"
            :title="title"
            :description="share_desc"
            :quote="quote"
            hashtags="Beauty 360, Malaysia Beauty salon"
          >
            <v-btn
              prepend-icon="mdi-whatsapp"
              variant="text"
              stacked
              color="green"
              ><p class="text-caption">WhatsApp</p></v-btn
            >
          </ShareNetwork>

          <ShareNetwork
            network="line"
            :url="shopUrl"
            :title="title"
            :description="share_desc"
            :quote="quote"
            hashtags="Beauty 360, Malaysia Beauty salon"
          >
            <v-btn prepend-icon="mdi-chat" variant="text" stacked color="green"
              ><p class="text-caption">Line</p></v-btn
            >
          </ShareNetwork>
        </v-row>
      </v-sheet>
    </v-dialog>
  </v-row>
</template>

<script scoped>
export default {
  props: {
    openSNSShareDialog: false,
   
    shopInfoObjects: {
      type: Object,
      default: () => ({}),
    },
  },

  created() {},

  mounted() {
    // console.log("SNS: %o", this.shopInfoObjects.detail.ai_review_short_zhcn);
  },

  data() {
    switch (this.$i18n.locale) {
      case "zhCN":
        return {
          title: this.shopInfoObjects.name + " | Beauty 360",
          share_desc: this.shopInfoObjects.detail.ai_review_short_zhcn,
          quote: "quote",
          shopUrl: "https://beauty-360.com/shop/" + this.shopInfoObjects.id,
        };

      default:
        return {
          title: this.shopInfoObjects.name + " | Beauty 360",
          share_desc: this.shopInfoObjects.detail.ai_review_short,
          quote: "quote",
          shopUrl: "https://beauty-360.com/shop/" + this.shopInfoObjects.id,
        };
    }
  },

  methods: {
    closeDialogWithResult() {
      this.$emit("result");
    },
  },
};
</script>