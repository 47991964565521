<template>
  <v-sheet class="pa-0 ma-0" color="white">
    <v-container class="pa-0 mt-4">
      <v-row no-gutters center warp class="d-flex">
        <v-col v-for="(staffInfo, index) in staffList" :key="index" cols="6" xs="3" sm="3">
          <v-card flat class="text-left ma-3" color="grey-lighten-3" @click="showStaffDetails(staffInfo)"
            :ripple="false">
            <v-row justify="center">
              <v-col cols="12" class="d-flex justify-center">
                <div style="width: 120px; height: 120px; margin-top: 20%; margin-bottom: 20%;">
                  <v-img :src="staffInfo.staff.picture_url" contain style="max-width: 100%; max-height: 100%;"></v-img>
                </div>
              </v-col>
              <v-icon icon="mdi-translate-variant" class="multi_lang" v-if="staffInfo.staff?.language" />
            </v-row>


            <v-card-text class="mt-n4">
              <div class="text-h6" style="font-weight: normal">
                {{ staffInfo.staff.display_name }}
              </div>

              <div class="mt-5 text-body-2" style="font-weight: normal">
                <v-icon icon="mdi-store" size="16" color="black" class="mt-n1" />
                {{ staffInfo.shop.name }}
              </div>

              <!-- <v-row class="mt-3">
                <v-col md="auto" v-if="staffInfo.staff.language">
                  {{ $t("staff.language") }}:
                </v-col>
                <v-slide-group @click.stop class="mb-1">
                  <template v-if="staffInfo.staff.language">
                    <v-chip v-for="(language, index) in staffInfo.staff.language.split(',')" :key="index">
                      {{ language.trim().charAt(0).toUpperCase() + language.trim().slice(1) }}
                    </v-chip>
                  </template>
                  </v-slide-group>
                  </v-row>
                  <v-row class="mt-5">
                    <v-col md="auto" v-if="staffInfo.staff.hobby">
                      {{ $t("staff.hobby") }}:
                    </v-col>
                    <v-slide-group @click.stop>
                      <template v-if="staffInfo.staff.hobby">
                                      <v-chip v-for="(hobby, index) in staffInfo.staff.hobby.split(',')" :key="index">
                                        {{ hobby.trim().charAt(0).toUpperCase() + hobby.trim().slice(1) }}
                                      </v-chip>
                                    </template>
                    </v-slide-group>
                  </v-row> -->
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="cardOnClick">
        <v-sheet rounded="lg" max-width="700" class="pa-0 mx-auto">
          <v-card>
            <v-card-actions class="justify-space-between">
              <p class="text-h6">{{ selectedStaff.shop?.name }}</p>
              <v-btn class="ma-0" icon="mdi-close" color="black" width="30" height="30" @click="cardOnClick = false"
                :ripple="false"></v-btn>
            </v-card-actions>
          </v-card>

          <v-img max-height="80%" :src="selectedStaff.staff?.picture_url" class="fill-width" cover
            aspect-ratio="1"></v-img>

          <v-col class="my-0">
            <v-simple-table>
              <tbody>
                <tr>
                  <td class="tdTitle">
                    <p class="text-subtitle-1 mx-5">
                      {{ $t("staff.name") }}
                    </p>
                  </td>

                  <td>
                    <p class="text-body-1 mt-0 mx-5" style="font-weight: normal">
                      {{ selectedStaff.staff?.display_name }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="tdTitle">
                    <p class="text-subtitle-1 mx-5">
                      {{ $t("staff.shop") }}
                    </p>
                  </td>

                  <td>
                    <p class="text-body-1 mt-0 mx-5" style="font-weight: normal">
                      {{ selectedStaff.shop?.name }}
                    </p>
                  </td>
                </tr>
                <tr v-if="selectedStaff.staff?.language">
                  <td class="tdTitle">
                    <p class="text-subtitle-1 mx-5">
                      {{ $t("staff.language") }}
                    </p>
                  </td>

                  <td>
                    <p class="text-body-1 mt-0 mx-5" style="font-weight: normal">
                      {{ selectedStaff.staff?.language ? selectedStaff.staff.language.split(',').map(word =>
          word.charAt(0).toUpperCase() + word.slice(1)).join(',') : '' }}
                    </p>
                  </td>
                </tr>
                <tr v-if="selectedStaff.staff?.hobby">
                  <td class="tdTitle">
                    <p class="text-subtitle-1 mx-5">
                      {{ $t("staff.hobby") }}
                    </p>
                  </td>

                  <td>
                    <p class="text-body-1 mt-0 mx-5" style="font-weight: normal">
                      {{ selectedStaff.staff?.hobby ? selectedStaff.staff.hobby.split(',').map(word =>
          word.charAt(0).toUpperCase() + word.slice(1)).join(',') : '' }}
                    </p>
                  </td>
                </tr>
                <tr v-if="selectedStaff.staff?.description">
                  <td class="tdTitle">
                    <p class="text-subtitle-1 mx-5">
                      {{ $t("staff.selfIntro") }}
                    </p>
                  </td>

                  <td>
                    <p class="text-body-1 mt-0 mx-5" style="font-weight: normal">
                      {{ selectedStaff.staff?.description }}
                    </p>
                  </td>
                </tr>

              </tbody>
            </v-simple-table>
          </v-col>

          <div align="center">
            <v-btn elevation="0" width="90%" color="green" class="mt-4 white--text mb-4" height="50px"
              variant="outlined" :ripple="false" :to="{ name: 'ReservationForm', params: { id: '32' } }">
              Arrange Appointment with {{ selectedStaff.staff?.display_name }}
            </v-btn>
          </div>
        </v-sheet>
      </v-dialog>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  props: {
    staffs: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      cardOnClick: false,
      staffList: [],
      selectedStaff: {},
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    if (this.staffs && this.staffs.length > 0) {
      this.staffList = JSON.parse(JSON.stringify(this.staffs))
    }
  },
  methods: {
    showStaffDetails(staffInfo) {
      this.selectedStaff = staffInfo;
      this.cardOnClick = true;
    },
  }
};

</script>

<style scoped>
.tdTitle {
  width: 25%;
  /* background-color: rgba(211, 211, 211, 0.217); */
  border: none;
  text-align: left;
}

.v-data-table>>>tr:hover {
  background: transparent !important;
}

tr {
  height: 80px !important;
}

.multi_lang {
  position: absolute;
  top: 12px;
  right: 12px;
}
</style>