<!-- https://github.com/xon52/v3-gmaps -->
<template>
  <div class="autoFrame" ref="autoFrame">
    <gmaps-map :options="mapOptions" @click="mark" @bounds_changed="setNewCoordinates">
      <gmaps-info-window ref="infoWA" :position="infoPopPosition" @closeclick="handleCloseClicked">
        <p class="text-body-2" style="font-weight: bold">
          {{ selectedShop }}
        </p>
      </gmaps-info-window>

      <gmaps-marker v-for="(item, i) in markerItems" :key="i" :options="item.options" @click="handleMarkerClick(i)" />
    </gmaps-map>

    <div class="over_map_center_list" ref="over_map_center_list">

      <div class="scrollContainer" ref="scrollContainer" @scroll="handleScroll">
        <div class="scrollContent" ref="scrollContent">
          <div class='pa-2' v-for="(shop, index) in shops" :key="index" :id="shop.shop_id" ref="shopItems">
            <ShopCell :shopObjects="shop" @shopCellPositionOnClick="shopCellPositionOnClick" />
          </div>
        </div>
      </div>

    </div>

    <div class="over_map_center_btn" ref="over_map_center_btn">
      <v-btn block rounded="xl" text="Search This Area" color="surface-variant" @click="serchAreaOnClick()">
      </v-btn>
    </div>
  </div>

  <v-dialog v-model="loadingDialog" persistent width="300">
    <v-sheet height="190" class="text-center">
      <v-progress-circular :indeterminate="loadingDialog" :size="100" color="blue" class="mt-12">
        {{ $t("homeview.loading") }}
      </v-progress-circular>
    </v-sheet>
  </v-dialog>
  <VSonner position="top-center" />
</template>

<script>
import { gmapsMap, gmapsMarker, gmapsInfoWindow } from "v3-gmaps";
import VueGeolocation from "vue-browser-geolocation";
import Constants from "@/uitls/Constants";
import ShopCell from "@/components/ShopCell.vue";
import Uitls from "@/uitls/Uitls";
import { VSonner, toast } from "vuetify-sonner";

export default {
  components: { gmapsMap, gmapsMarker, gmapsInfoWindow, ShopCell, VSonner },

  data: () => ({
    shops: [],
    markerItems: [],
    frameHeight: window.innerHeight,
    infoPopPosition: { lat: null, lng: null },
    loadingDialog: true,
    selectedShop: null,
    mapOptions: {
      center: {
        lat: 3.142966266741321,
        lng: 101.68441409143318,
      },
      // gestureHandling: "cooperative",
      zoom: 16,
      zoomControl: false,
      scaleControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      rotateControl: false,
      streetViewControl: false,
      clickableIcons: false,
      disableDefaultUI: true,
    },

    coordinatesMap: null,
  }),

  created() {
    VueGeolocation.getLocation({
      enableHighAccuracy: false,
      timeout: Infinity,
      maximumAge: 0,
    })
      .then((coordinates) => {
        if (!Uitls.isEmpty(coordinates)) {
          this.$cookies.set("coordinates", coordinates);
          this.mapOptions = {
            center: {
              lat: parseFloat(coordinates.lat),
              lng: parseFloat(coordinates.lng),
            },
            zoom: 16,
          };
          this.loadNearbyLocationShop(coordinates);
        } else {
          const tmpCoordinates = {
            lat: 3.142966266741321,
            lng: 101.68441409143318,
          };
          this.loadNearbyLocationShop(tmpCoordinates);
        }
      })
      .catch((error) => {
        console.error(error);
        const tmpCoordinates = {
          lat: 3.142966266741321,
          lng: 101.68441409143318,
        };
        this.loadNearbyLocationShop(tmpCoordinates);
      });
  },

  methods: {
    handleMarkerClick(i) {
      this.$refs.infoWA.open();

      const element = document.getElementById(this.shops[i].shop_id);
      element.scrollIntoView({ behavior: "smooth" });
      this.selectedShop = this.shops[i].shop_title;
      this.infoPopPosition = {
        lat: parseFloat(this.shops[i].shop_lat),
        lng: parseFloat(this.shops[i].shop_lng),
      };
    },

    async loadNearbyLocationShop(coordinates) {
      const params = {
        product_id: Constants.BEAUTY_360_PRODUCT_ID,
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        page: 1,
        per_page: 20,
      };

      await this.$store
        .dispatch("shopStore/locationSearchShop", params)
        .then((response) => {
          if (response.data.length == 0) {
            this.loadNormalShop();
            return;
          }

          const shopData = [];
          response.data.forEach((shop) => {
            if (shop.status) {
              var shop_img_small = Constants.NO_IMAGE;
              if (shop.pictures.length > 0) {
                shop_img_small = shop.pictures[0].picture_url_small;
              }

              shopData.push(Uitls.setShopDetails(shop, shop_img_small));
            }
          });
          this.shops = shopData;
          this.loadShopMarkerData();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async loadNormalShop() {
      const params = {
        product_id: Constants.BEAUTY_360_PRODUCT_ID,
        page: 1,
        per_page: 20,
        sort: "-id",
      };

      await this.$store
        .dispatch("shopStore/fetchShops", params)
        .then((response) => {
          const shopData = [];
          response.data.forEach((shop, i) => {
            if (shop.status) {
              var shop_img_small = Constants.NO_IMAGE;
              if (shop.pictures.length > 0) {
                shop_img_small = shop.pictures[0].picture_url_small;
              }

              shopData.push(Uitls.setShopDetails(shop, shop_img_small));
            }
          });
          this.shops = shopData;
          this.loadShopMarkerData();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    loadShopMarkerData() {
      this.loadingDialog = false;
      for (let i in this.shops) {
        const shopLocationData = {
          options: {
            position: {
              lat: parseFloat(this.shops[i].shop_lat),
              lng: parseFloat(this.shops[i].shop_lng),
            },
          },
        };
        this.markerItems.push(shopLocationData);
      }

      this.shopCellPositionOnClick()
    },

    shopCellPositionOnClick(shopObject = null) {
      if (shopObject && shopObject.shop_lat && shopObject.shop_lng) {
        this.mapOptions = {
          center: {
            lat: parseFloat(shopObject.shop_lat),
            lng: parseFloat(shopObject.shop_lng),
          },
          zoom: 16,
        };
      } else if (this.shops.length > 0) {
        const target = this.shops.find(v => v.shop_lat && v.shop_lng)
        if (target) {
          this.mapOptions = {
            center: {
              lat: parseFloat(target.shop_lat),
              lng: parseFloat(target.shop_lng),
            },
            zoom: 16,
          };
        }
      }
    },

    mark(event) {
      // console.log(event);
    },
    serchAreaOnClick() {
      this.shops = [];
      this.markerItems = [];

      const tmpCoordinates = {
        lat: this.coordinatesMap.north,
        lng: this.coordinatesMap.west,
      };
      this.loadNearbyLocationShop(tmpCoordinates);
    },
    setNewCoordinates(newCoordinates) {
      this.coordinatesMap = newCoordinates;
    },

    handleScroll() {
      const shopItems = this.$refs.shopItems;
      let visibleShopId = null;

      // Check which shop item is in view
      for (let shopItem of shopItems) {
        const rect = shopItem.getBoundingClientRect();
        const itemCenter = rect.left + rect.width / 2;
        if (itemCenter >= 0 && itemCenter <= window.innerWidth) {
          visibleShopId = shopItem.id;
          break;
        }
      }

      if (visibleShopId) {
        // console.log('Currently viewing shop with ID:', visibleShopId);
        const target = this.shops.find(v => v.shop_id == visibleShopId)
        if (target) {
          this.shopCellPositionOnClick(target)
        }
      }
    },
  },
};
</script>

<style scoped>
.autoFrame {
  position: relative;
  width: 100%;
  background: white;
  height: calc(100vh - 52px);
}

.scrollContent {
  display: flex;
  flex-wrap: nowrap;
}

.scrollContainer {
  width: 100vw;
  overflow-x: scroll;
  scrollbar-width: none;
}

.over_map_center_list {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.over_map_center_btn {
  position: absolute;
  top: 2%;
  width: 40%;
  margin-left: 30%;
  margin-right: 30%;
}
</style>