<template>
  <div class="mt-2">
    <p
      v-if="this.$i18n.locale == 'zhCN'"
      v-html="shopDetail.detail.ai_highlight_zhcn"
      class="text-body-1"
      style="font-weight: normal"
    ></p>
    <p
      v-else-if="this.$i18n.locale == 'ms'"
      v-html="shopDetail.detail.ai_highlight_ms"
      class="text-body-1"
      style="font-weight: normal"
    ></p>
    <p
      v-else
      v-html="shopDetail.detail.ai_highlight"
      class="text-body-1"
      style="font-weight: normal"
    ></p>
  </div>

  <!-- Features -->
  <!-- <v-col class="mt-6">
    <v-row row warp>
      <v-icon color="light-blue" size="10" class="mt-2" icon="mdi-circle" />
      <h3 class="font-weight-light mx-2">Features</h3>

      <v-row justify="left" class="mt-0 d-flex">
        <v-col
          cols="6"
          xs="3"
          sm="3"
          v-for="feature in featuresList"
          :key="feature.name"
        >
          <v-card flat color="grey-lighten-5">
            <v-img
              src="/hair.jpg"
              :aspect-ratio="this.isMobileDevice.value ? 1 : 16 / 9"
              height="200"
              cover
            ></v-img>
            <div class="text-h5 ma-2" style="font-weight: normal">
              {{ feature.name }}
            </div>
            <div class="text-body-2 ma-4" style="font-weight: normal">
              {{ feature.detail }}
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-row>
  </v-col> -->

  <!-- Shop Atmosphere -->
  <!-- <div>
    <v-col class="mt-10">
      <v-row row warp>
        <v-icon color="light-blue" size="10" class="mt-2" icon="mdi-circle" />
        <h3 class="font-weight-light mx-2">Atmosphere of the shop</h3>
      </v-row>
    </v-col>

    <v-sheet class="mt-4">
      <v-slide-group center-active class="v-slide-group__wrapper">
        <v-slide-group-item v-for="n in 6" :key="n">
          <v-card
            style="margin: 0 20px 20px 0; width: 320px; height: 200px"
            elevation="2"
          >
            <v-img
              src="/salon.jpeg"
              height="200"
              cover
              aspect-ratio="1"
            ></v-img>
          </v-card>
        </v-slide-group-item>
      </v-slide-group>

      https://github.surmon.me/vue-awesome-swiper
      <swiper
        :modules="modules"
        :loop="true"
        :slides-per-view="3"
        :slides-per-group="3"
        :pagination="{ clickable: true }"
        :watch-slides-progress="true"
        :prevent-clicks="false"
        :prevent-clicks-propagation="false"
      >
        <swiper-slide v-for="n in 12" :key="n">
          <v-card
            style="margin: 0 20px 20px 0; width: 320px; height: 200px"
            elevation="2"
          >
            <v-img
              src="/salon.jpeg"
              height="200"
              cover
              aspect-ratio="1"
            ></v-img>
          </v-card>
        </swiper-slide>
      </swiper>
    </v-sheet>
  </div> -->

  <!-- UsageInfo -->
  <!-- <div>
    <v-col class="mt-10">
      <v-row row warp>
        <v-icon color="light-blue" size="10" class="mt-2" icon="mdi-circle" />
        <h3 class="font-weight-light mx-2">Usage Trends</h3>
      </v-row>

      <v-row row warp class="mt-6" justify="center">
        <v-progress-circular
          model-value="20"
          :size="100"
          :width="30"
          color="red"
        ></v-progress-circular>
        <div class="mx-4 mt-5">
          <span>Female 80%</span>
          <br />
          <span>Male 20%</span>
        </div>
      </v-row>

      <v-col class="pa-4">
        <v-row row warp class="mt-6">
          <span>~10 years old</span>
          <v-progress-linear
            rounded
            model-value="10"
            color="light-blue"
            :height="26"
            class="mx-auto"
          ></v-progress-linear>
        </v-row>

        <v-row row warp class="mt-6">
          <span>20 years old~</span>
          <v-progress-linear
            rounded
            model-value="20"
            color="light-blue"
            :height="26"
            class="mx-auto"
          ></v-progress-linear>
        </v-row>

        <v-row row warp class="mt-6">
          <span>30 years old~</span>
          <v-progress-linear
            rounded
            model-value="50"
            color="light-blue"
            :height="26"
            class="mx-auto"
          ></v-progress-linear>
        </v-row>

        <v-row row warp class="mt-6">
          <span>40 years old~</span>
          <v-progress-linear
            rounded
            model-value="30"
            color="light-blue"
            :height="26"
            class="mx-auto"
          ></v-progress-linear>
        </v-row>
      </v-col>
    </v-col>
  </div> -->

  <v-sheet v-if="tagss.length > 0" class="mt-6">
    <v-chip-group v-model="chipSelect">
      <v-chip
        v-for="i in tagss"
        :key="i"
        variant="text"
        size="small"
        @click="chipClick(i)"
        style="font-weight: bold"
      >
        #{{ stringReplace(i) }}
      </v-chip>
    </v-chip-group>
  </v-sheet>
</template>

<script>
import SwiperClass, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css";
import "swiper/css/pagination";

export default {
  props: {
    shopDetail: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      modules: [Pagination],
      tagss: [],
    };
  },

  watch: {
    shopDetail() {
      const vm = this;
      vm.handleShopAbout();
    },
  },
  mounted() {
    const vm = this;
    vm.handleShopAbout();
  },

  methods: {
    handleShopAbout() {
      if (this.shopDetail) {
        if (this.shopDetail.tags) {
          if (typeof this.shopDetail.tags === "string") {
            const string = this.shopDetail.tags;
            this.tagss = string.split(",");
          } else if (Array.isArray(this.shopDetail.tags)) {
            this.tagss = this.shopDetail.tags;
          }
        }
      }
    },
    stringReplace(string) {
      return string
        .replace(/["']/g, "")
        .replace("[", "")
        .replace("]", "")
        .trim();
    },

    chipClick(value) {
      this.$emit("chipTagOnClickCallback", this.stringReplace(value));
    },
  },
};
</script>

<style scoped>
.v-slide-group__wrapper {
  touch-action: auto;
  overflow: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}
</style>