<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    color="white"
    max-width="1200"
    rounded="lg"
    width="100%"
    elevation="0"
  >
    <!-- <v-card color="black" class="mt-6 ma-4" variant="outlined">
      <v-card-item>
        <v-card-title>
          <v-icon color="red" icon="mdi-alert" class="mt-n1"></v-icon>
          <span class="text-h5 mx-4 text-black">{{ $t('error.404')}}</span>
          </v-card-title>

        <v-card-subtitle class="mt-4">
          <span class="text-body-1 text-black">{{ $t('error.page_notfound')}}</span>
        </v-card-subtitle>
      </v-card-item>
    </v-card> -->

    <div>
      <v-sheet
        elevation="0"
        max-width="600"
        rounded="lg"
        width="100%"
        class="pa-4 text-center mx-auto"
      >
        <v-icon
          class="mb-5"
          color="blue"
          icon="mdi-store-alert"
          size="112"
        ></v-icon>

        <h2 class="text-h5 mb-6">{{ $t("error.page_notfound") }}</h2>

        <p class="mb-4 text-medium-emphasis text-body-2">
          {{ $t("settingpage.like_shop_desc") }}
          <br />

          <a :href="'/'" class="text-decoration-none text-info">{{
            $t("settingpage.find_shop")
          }}</a>
        </p>
      </v-sheet>
    </div>
  </v-sheet>
</template>
<script scoped>
export default {
  mounted() {
    console.error("Not Found");
  },
};
</script>