<template>
  <v-card
    class="pa-2"
    style="width: 340px"
    elevation="1"
    color="white"
    :ripple="false"
  >
    <router-link
      :to="{
        name: 'Shop',
        params: { id: shopObjects.shop_id, openPage: '1', scrollPage: '0' },
      }"
      style="text-decoration: none; color: inherit"
    >
      <v-row no-gutters>
        <v-col cols="4">
          <v-img
            :lazy-src="local_shop"
            :src="shopObjects.shop_img_small"
            aspect-ratio="1"
            height="122"
            max-width="280"
            cover
          >
          </v-img>
        </v-col>

        <v-col cols="8">
          <div class="mx-2 mt-2" v-if="shopObjects.shop_meter">
            <v-icon
              icon="mdi-map-marker-distance"
              color="red"
              size="16"
              class="mx-0 mt-n1"
            />
            <span style="font-size: 12px" class="mx-2">
              {{ shopObjects.shop_meter.toFixed(1) }}km
            </span>
          </div>

          <div class="mx-2">
            <v-icon
              icon="mdi-briefcase"
              color="black"
              size="16"
              class="mx-0 mt-n1"
            />
            <span style="font-size: 12px" class="mx-2 text-black">
              {{ shopObjects.shop_opening_times }}~{{ shopObjects.shop_closing_times }}
            </span>
          </div>

          <div class="mx-2">
            <v-icon
              icon="mdi-cash"
              color="black"
              size="16"
              class="mx-0 mt-n1"
            />
            <span style="font-size: 12px" class="mx-2 text-black">
              {{ shopPriceRange() }}
            </span>
          </div>
        </v-col>
      </v-row>

      <v-row class="mt-n2 mb-1">
        <v-col>
          <div
            v-snip="{ lines: 2 }"
            class="text-body-1 mx-2 text-black"
            style="font-weight: bold"
          >
            {{ shopObjects.shop_title }}
          </div>

          <div class="mx-2">
            <v-icon
              icon="mdi-map-marker"
              size="16"
              color="grey"
              class="mx-0 mt-n1"
            />
            <span class="mx-2 text-black" style="font-size: 12px">
              {{ $t(prefectureCodeToString(shopObjects.shop_prefecture)) }}
            </span>
          </div>
        </v-col>
      </v-row>
    </router-link>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        size="small"
        color="blue"
        icon="mdi-map-marker-radius"
        elevation="1"
        variant="outlined"
        @click="shopPosition"
      ></v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Uitls from "@/uitls/Uitls";

export default {
  props: {
    shopObjects: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props) {
    // setup() receives props as the first argument.
    // console.log(props.shopObjects);
    // console.log(props.shopObjects.shop_crowdfundings[0]);
  },

  data() {
    return {
      local_shop: require("@/assets/img/logo120.png"),
      bot_logo_src: require("@/assets/img/robot_120.png"),
    };
  },

  methods: {
    shopPosition() {
      this.$emit("shopCellPositionOnClick", this.shopObjects);
    },

    prefectureCodeToString(code) {
      return Uitls.prefectureCodeToString(code);
    },
    shopPriceRange() {
      return this.shopObjects ? Uitls.calculateShopPriceRange(this.shopObjects) : 'RM-'
    },
  },
};
</script>

<style></style>