<template>
  <v-sheet class="pa-0 ma-0" color="white">
    <v-card
      class="mx-auto"
      color="yellow"
      :title="$t('shop.comingSoon')"
      :subtitle="$t('shop.underConstruction')"
      prepend-icon="mdi-alert"
    ></v-card>
    <br />
    <p class="text-h5 mx-2" style="font-weight: bold">
      <v-icon
        icon="mdi-trophy-award"
        color="blue-darken-1"
        size="24px"
        class="mb-1"
      ></v-icon>
      {{ $t("homeview.top_stylist") }}
    </p>

    <v-container>
      <v-row no-gutters warp justify="center" class="d-flex">
        <v-col v-for="(staffInfo, index) in recommendedStaffList" :key="index" cols="6" sm="4">
          <v-card class="ma-4" max-width="100%" tile color="white">
            <v-img height="80%" src="/salon.jpeg"></v-img>
            <v-col>
              <v-avatar size="90px" style="position: absolute; top: 40%">
                <v-img :src="staffInfo.staff.picture_url" class="fill-width"></v-img>
              </v-avatar>
            </v-col>
            <v-list-item color="rgba(0, 0, 0, 1.0)" class="mb-2">
              <v-list-item-content>
                <div class="text-h5">{{ staffInfo.staff.display_name }}</div>
                <div class="mt-1 text-body-2" style="font-weight: normal">
                  <v-icon
                    icon="mdi-store"
                    size="16"
                    color="black"
                    class="mt-n1"
                  />
                  {{ staffInfo.shop.name }}
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div align="center" v-if="showMoreBtn === 'yes'">
      <v-btn
        width="40%"
        elevation="0"
        class="ma-6 border"
        height="50px"
        :ripple="false"
        rounded="pill"
      >
        {{ $t("homeview.show_more") }}</v-btn
      >
    </div>
  </v-sheet>
</template>

<script>
export default {
  props: {
  staffs: {
    type: Array,
    default: () => [],
  },
  showMoreBtn: {
    type: Boolean,
    default: false
  }
},

  data() {
    return {
      recommendedStaffList: [],
      shopList: [
        { name: "Earth Beauty", review: "★★★★★", avatar: "/salon.jpeg" },
        { name: "Asahi Salon", review: "★★★", avatar: "/salon.jpeg" },
        { name: "Premium Salon", review: "★★★★", avatar: "/salon.jpeg" },
        { name: "Nail Salon", review: "★★★★★", avatar: "/salon.jpeg" },
      ],

      categoryList: [
        { name: "Nail", avatar: "/nail.jpg" },
        { name: "Body care", avatar: "/nail.jpg" },
        { name: "Hair Cut", avatar: "/nail.jpg" },
        { name: "Aromatherapy", avatar: "/nail.jpg" },
        { name: "Hair Cut", avatar: "/nail.jpg" },
        { name: "Hair Cut", avatar: "/nail.jpg" },
        { name: "Hair Cut", avatar: "/nail.jpg" },
      ],
    };
  },
  mounted() {
    if (this.staffs && this.staffs.length > 0) {
      const staffList = JSON.parse(JSON.stringify(this.staffs));

      if (staffList.length > 0) {
        this.recommendedStaffList = staffList.filter((item) => item.staff && item.staff.is_recommend && item.staff.is_recommend == 1)
      }
    }
  }
};
</script>

<style></style>