<template>
  <br />
  <v-sheet class="pa-0 ma-0">
    <p class="text-h5 mx-2" style="font-weight: bold">
      <v-icon
        icon="mdi-image"
        color="blue-darken-1"
        size="24px"
        class="mb-1"
      ></v-icon>
      {{ $t("shop.random_gallery") }}
    </p>

    <v-container>
      <v-col cols="12">
        <v-row justify="center" no-gutters>
          <v-btn-toggle
            v-model="selected"
            rounded="xl"
            color="deep-purple-accent-3"
            group
            density="compact"
            variant="outlined"
            mandatory
            @click="change(selected)"
          >
            <v-btn value="" size="small" :ripple="false">{{
              $t("shop.all")
            }}</v-btn>
            <v-btn value="shop_work" size="small" :ripple="false">{{
              $t("shop.work")
            }}</v-btn>
            <v-btn value="shop_interior" size="small" :ripple="false">{{
              $t("shop.shop")
            }}</v-btn>
            <v-btn value="shop_other" size="small" :ripple="false">{{
              $t("shop.other")
            }}</v-btn>
          </v-btn-toggle>
        </v-row>
      </v-col>

      <v-row no-gutters warp justify="center" class="mt-2">
        <v-col
          v-for="(image, index) in shopImages"
          :key="image.id"
          class="d-flex child-flex"
          :cols="this.isMobileDevice.value ? 4 : 3"
        >
          <v-img
            :src="image.picture_url_small"
            :lazy-src="image.picture_url_small"
            aspect-ratio="1"
            cover
            class="bg-grey-lighten-2"
            @click="imagePreviewClick(index)"
            alt=""
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey-lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>
    </v-container>

    <!-- <div align="center">
      <v-btn
        width="40%"
        elevation="0"
        class="ma-6 border"
        height="50px"
        :ripple="false"
        rounded="pill"
        >{{ $t("homeview.show_more") }}</v-btn
      >
    </div> -->
  </v-sheet>

  <v-dialog v-model="fullImage">
    <v-card
      class="mx-auto"
      :max-width="isMobileDevice.value ? '300px' : '50%'"
      height="90%"
    >
      <v-toolbar>
        <v-spacer></v-spacer>
        <v-btn
          :ripple="false"
          icon="mdi-close"
          color="black"
          width="30"
          height="30"
          variant="text"
          @click="fullImage = false"
        >
        </v-btn>
      </v-toolbar>
      <div align="center">
        <v-img
          class="full-image"
          :src="selectedShop.picture_url_small"
          :lazy-src="local_logo"
          cover
          :max-width="isMobileDevice.value ? '300px' : '50%'"
          :alt="selectedShop.shop.name"
        >
        </v-img>
      </div>
      <v-card-title>
        <v-icon
          class="me-1 pb-1"
          color="blue"
          icon="mdi-store"
          size="18"
        ></v-icon>
        {{ selectedShop.shop.name }}
      </v-card-title>

      <v-card-subtitle>
        <v-icon
          icon="mdi-map-marker"
          size="16"
          color="grey"
          class="mx-0 mt-n1"
        />
        <span class="mx-2" style="font-size: 12px">
          {{ $t(prefectureCodeToString(selectedShop.shop.prefecture)) }}
        </span>
      </v-card-subtitle>

      <!-- Tags -->
      <v-card-text v-if="tagss.length > 0">
        <v-chip-group>
          <v-chip
            v-for="i in tagss"
            :key="i"
            variant="text"
            class="ma-0"
            size="small"
            @click="chipClick(i)"
            style="font-weight: bold"
          >
            #{{ stringReplace(i) }}
          </v-chip>
        </v-chip-group>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue"
          variant="outlined"
          :ripple="false"
          @click="shopDetailOnClick(selectedShop.shop_id)"
        >
          {{ $t("shop.shop_details") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import uitls from "@/uitls/Uitls";
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";

export default {
  components: {
    VueBottomSheet,
  },

  data() {
    return {
      selected: 0,
      shopImages: [],
      images: [],
      local_logo: require("@/assets/img/logo120.png"),
      fullImage: false,
      selectedShop: null,
      tagss: [],
    };
  },

  computed: {
    selected: {
      get() {
        return this.selected;
      },
      set(v) {
        this.selected = v;
        this.$forceUpdate();
      },
    },
  },

  mounted() {
    this.getShopPictures("");
  },

  methods: {
    change(val) {
      this.getShopPictures(val);
    },

    imagePreviewClick(index) {
      this.selectedShop = this.shopImages[index];
      this.fullImage = true;

      if (!uitls.isEmpty(this.selectedShop.shop.tags)) {
        const string = this.selectedShop.shop.tags;
        this.tagss = string.split(",");
      }
    },

    async getShopPictures(value) {
      const params = {
        limit: this.isMobileDevice.value ? 20 : 40,
        category: value ? value : "",
      };

      await this.$store
        .dispatch("shopStore/fetchRandomShopPictures", params)
        .then((response) => {
          this.shopImages = response;

          for (let index = 0; index < this.shopImages.length; index++) {
            const element = this.shopImages[index].picture_url_small;
            this.images.push(element);
          }
        })
        .catch((error) => {
          alert(error);
        });
    },

    shopDetailOnClick(shopID) {
      this.$router.push({
        name: "Shop",
        params: { id: shopID },
      });
    },

    prefectureCodeToString(code) {
      return uitls.prefectureCodeToString(code);
    },

    stringReplace(string) {
      return string
        .replace(/["']/g, "")
        .replace("[", "")
        .replace("]", "")
        .trim();
    },

    chipClick(value) {
      this.$router.push({
        name: "SearchResult",
      });
    },
  },
};
</script>

<style scoped>
</style>