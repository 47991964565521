<template>
  <v-sheet class="pa-0 ma-0" color="white">
    <v-expansion-panels multiple class="mt-2">
      <v-expansion-panel
        elevation="0"
        v-for="(value, key) in menu_dict_type"
        v-bind:key="key"
      >
        <v-expansion-panel-title class="select-none">
          <p class="text-body-1" style="font-weight: normal">
            {{ key }}
          </p>
        </v-expansion-panel-title>

        <v-expansion-panel-text>
          <v-row no-gutters center warp class="d-flex">
            <v-col cols="12" xs="12" sm="12" md="12">
              <v-sheet>
                <v-simple-table>
                  <tbody class="mx-6">
                    <tr v-for="(item, index) in value" v-bind:key="index">
                      <td class="tdLeft">
                        <p class="divider text-body-2">{{ item.title }}</p>
                      </td>
                      <td class="tdRight">
                        <p class="text-body-2 text-black tdRight">
                          RM {{ item.amount }}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-sheet>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-sheet>
</template>

<script>
import CuponSheet from "@/components/CuponSheet.vue";
import CrowdfundingsCard from "@/components/CrowdfundingsCard.vue";

export default {
  props: {
    shopData: {
      type: Object,
      default: () => ({}),
    },
  },

  components: { CuponSheet, CrowdfundingsCard },

  setup(props) {},

  computed: {},

  data() {
    return {
      menu_dict_type: {},

      haircutList: ["HAIR DESIGNER", "CREATIVE DIRECTOR", "MASTER TRAINER"],
      shortcutList: ["shopprice.cut", "shopprice.color", "shopprice.prem"],
    };
  },

  mounted() {
    this.seperateMenuDictType();
  },

  methods: {
    seperateMenuDictType() {
      for (let index = 0; index < this.shopData.menus.length; index++) {
        const element = this.shopData.menus[index];

        if (this.menu_dict_type.hasOwnProperty(element.menu_type)) {
          this.menu_dict_type[element.menu_type].push(element);
        } else {
          this.menu_dict_type[element.menu_type] = [element];
        }
      }
    },
  },
};
</script>

<style scoped>
tr {
  height: 40px !important;
}

tbody {
  /* background-color: green; */
  display: block;
  width: 100%;
}

.tdLeft {
  width: 100%;
  min-width: 200px;
  table-layout: fixed;
  text-align: left;
  /* border: 1px solid black; */
  padding: 0px;
}

.tdRight {
  table-layout: fixed;
  min-width: 90px;
  text-align: right;
  /* border: 1px solid black; */
  padding: 0px;
}

.divider {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0;
}

.divider::after {
  content: "";
  height: 0.5px;
  background: lightgray;
  flex: 1;
  margin: 0em 0.5em 0em 1em;
}
</style>