<!-- https://webxreal.com/js-vue-vuetify-dialog/? -->
<template>
  <v-row justify="center">
    <v-dialog class="ma-3" v-model="this.openSearchFilterDialog" fullscreen :scrim="true"
      transition="dialog-bottom-transition">
      <v-sheet class="pa-0 ma-0 mx-auto" color="white" rounded="lg" width="100%" elevation="0">
        <v-toolbar dark color="blue" height="48">
          <v-icon class="mx-4">mdi-tune-variant</v-icon>
          <v-spacer></v-spacer>
          <v-btn class="ma-4" elevation="0" density="comfortable" icon="mdi-close" :ripple="false"
            @click="closeDialog"></v-btn>
        </v-toolbar>

        <!-- <v-tabs
          class="mt-0"
          v-model="tab"
          bg-color="clear"
          color="blue"
          center-active
          grow
        >
          <v-tab value="tab-1" :ripple="false"
            ><v-icon start icon="mdi-magnify" />
            <p class="text-subtitle-1">{{ $t("searchfilter.basic") }}</p></v-tab
          >
          <v-tab value="tab-2" :ripple="false"
            ><v-icon start icon="mdi-more" />
            <p class="text-subtitle-1">{{ $t("searchfilter.more") }}</p></v-tab
          >
        </v-tabs> -->

        <v-window v-model="tab" :touch="false">
          <v-window-item value="tab-1">
            <v-text-field class="pa-4 mt-2" variant="outlined" prepend-inner-icon="mdi-magnify" v-model="searchKeyword"
              clearable hide-details>
              <v-field-label style="font-weight: normal">
                {{ $t("homeview.search_keyword") }}
              </v-field-label>
            </v-text-field>

            <v-select
              prepend-inner-icon="mdi-map-marker-outline"
              transition="none"
              class="mt-4 ma-4"
              variant="outlined"
              :label="$t('shop.prefecture')"
              v-model="prefecture_v2"
              :items="prefectureList"
              :item-title="(v) => v.code ? $t('prefecture.' + v.code) : v.name"
              item-value='id'
            ></v-select>

            <v-select prepend-inner-icon="mdi-pound" transition="none" class="mt-4 ma-4" :label="$t('searchfilter.tag')"
              :items="beautyServiceTags" :item-title="(beautyServiceTags) => $t(beautyServiceTags)" variant="outlined"
              chips multiple v-model="selectedTag"></v-select>

            <v-select class="mt-0 ma-4" prepend-inner-icon="mdi-clock-time-eight-outline" transition="none"
              :label="$t('searchfilter.available_time')" :items="timeList" variant="outlined"
              v-model="selectedTime"></v-select>

            <!-- <v-row dense>
              <v-col cols="6">
                <v-text-field
                  class="mt-6 ma-4"
                  v-model="trip.start"
                  label="Available Date"
                  type="date"
                  variant="underlined"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-select
                  transition="none"
                  class="mt-6 ma-4"
                  :label="$t('searchfilter.available_time')"
                  :items="timeList"
                  variant="underlined"
                  v-model="selectedTime"
                ></v-select>
              </v-col>
            </v-row> -->

            <!-- <v-row dense>
              <v-col cols="6">
                <v-select
                  transition="none"
                  class="mt-6 ma-4"
                  label="Min Price(RM)"
                  :items="priceList"
                  variant="underlined"
                ></v-select>
              </v-col>

              <v-col cols="6">
                <v-select
                  transition="none"
                  class="mt-6 ma-4"
                  label="Max Price(RM)"
                  :items="priceList"
                  variant="underlined"
                ></v-select>
              </v-col>
            </v-row> -->

            <v-row class="ma-1 mt-n6">
              <v-col cols="12" class="text-subtitle-2 text-grey-darken-3">
                <p>{{ $t("searchfilter.price_range") }}</p>
                <p class="text-subtitle-1" v-if="priceRange[0] > 0 || priceRange[1] < 1000">
                  RM{{ priceRange[0] }} - RM{{ priceRange[1] }}
                  <span class="text-caption text-red">{{ $t('searchfilter.some_shop_not_show') }}</span>
                </p>
              </v-col>
              <v-col cols="12" class="mt-2">
                <v-range-slider color="blue" class="align-center" :max="1000" :min="0" :step="10" v-model="priceRange"
                  thumb-label="always"></v-range-slider>
              </v-col>
            </v-row>
          </v-window-item>

          <v-window-item value="tab-2">
            <v-select transition="none" class="mt-6 ma-4" label="Gender" :items="genderTags"
              variant="underlined"></v-select>

            <v-select transition="none" class="mt-6 ma-4" label="Hair Issue" :items="issueTags"
              variant="underlined"></v-select>

            <v-select transition="none" class="mt-6 ma-4" label="Hair Style" :items="hairLenghtTags"
              variant="underlined"></v-select>

            <v-select transition="none" class="mt-6 ma-4" label="Tags" :items="otherTags"
              variant="underlined"></v-select>

            <v-select transition="none" class="mt-6 ma-4" label="TrainStation" :items="prefectureList"
              variant="underlined"></v-select>
          </v-window-item>
        </v-window>

        <v-row justify="center" no-gutters>
          <v-btn prepend-icon="mdi-magnify" elevation="0" color="blue" width="93%" class="ma-6" height="50px"
            :ripple="false" rounded="pill" @click="startSearchOnClick">
            {{ $t("searchfilter.search") }}
          </v-btn>
        </v-row>
      </v-sheet>
    </v-dialog>
  </v-row>
</template>

<script>
import Uitls from "@/uitls/Uitls";

export default {
  props: {
    openSearchFilterDialog: false,

    searchFilterObjects: {
      type: Object,
      default: () => ({}),
    },
    prefectureList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      prefectureListLoaded: false,
      searchKeyword: "",
      priceRange: [50, 300],
      tab: null,
      selectedTag: [],
      timeList: Uitls.timeList(),
      beautyServiceTags: Uitls.GPTSummaryTags(),
      selectedTime: "",
      prefecture_v2: 0,

      trip: {
        name: "",
        location: null,
        start: null,
        end: null,
      },
      genderTags: ["All", "Male", "Female", "Kids"],
      priceList: [
        "Any",
        "RM10",
        "RM20",
        "RM30",
        "RM40",
        "RM50",
        "RM60",
        "RM70",
        "RM80",
        "RM90",
        "RM100",
        "RM200",
        "RM300",
        "RM400",
        "RM500",
      ],
      issueTags: ["All", "Frizzy Hair", "Dry Hair", "Thinning Hair"],
      hairLenghtTags: [
        "All",
        "Very Short",
        "Short",
        "Medium",
        "Semi Long",
        "Long",
      ],
      otherTags: [
        "All",
        "Luxury",
        "Free cut Model",
        "SNS(TikTik, Instagram)",
        "Low Budget",
        "Japan Style",
        "Korea Style",
        "Family Friendly",
        "Islam Friendly",
        "Discount",
      ],
    };
  },

  mounted() {
    this.searchKeyword = this.searchFilterObjects.searchKeyword;
    this.selectedTag = this.searchFilterObjects.selectedTag;
    this.selectedTime = this.searchFilterObjects.selectedTime;
    this.priceRange = this.searchFilterObjects.priceRange;
    this.prefecture_v2 = this.searchFilterObjects.prefecture_v2;
  },
  methods: {
    closeDialog() {
      this.$emit("result", { newSearchValue: false });
    },

    startSearchOnClick() {
      this.$emit("result", {
        newSearchValue: true,
        searchKeyword: this.searchKeyword,
        selectedTag: this.selectedTag,
        selectedTime: this.selectedTime,
        priceRange: this.priceRange,
        prefecture_v2: this.prefecture_v2,
      });
    },
  },
};
</script>