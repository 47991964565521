<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    color="white"
    max-width="1200"
    rounded="lg"
    width="100%"
    elevation="0"
  >
    <v-container>
      <p class="text-h5 mx-2" style="font-weight: bold">
        <v-icon
          icon="mdi-newspaper"
          color="light-blue"
          size="24px"
          class="mb-1"
        ></v-icon>
        {{ $t("homeview.new_topics") }}
      </p>

      <!-- Category Tag -->
      <v-slide-group center-active active-class="indigo" v-model="selection">
        <v-slide-group-item
          v-for="(item, index) in articleCategories"
          :key="item.name"
          v-slot="{ isSelected }"
        >
          <v-btn
            class="ma-2"
            rounded
            prepend-icon="mdi-bookmark"
            elevation="0"
            :color="isSelected ? 'blue' : 'grey'"
            variant="outlined"
            :ripple="false"
            @click="categoryOnClick(item.id, index)"
          >
            {{ $t("utls." + item.name) }}
          </v-btn>
        </v-slide-group-item>
      </v-slide-group>

      <v-text-field
        class="ma-4"
        variant="outlined"
        v-model="searchKeyword"
        single-line
        hide-details
        clearable
        prepend-inner-icon="mdi-magnify"
        @keydown.enter.prevent="startSearchOnClick"
      >
        <v-field-label style="font-weight: normal">{{
          $t("searchfilter.keyword")
        }}</v-field-label>
      </v-text-field>

      <!-- ArticleList -->
      <v-container fluid class="mt-4">
        <v-row>
          <v-col
            v-for="item in list"
            :key="item.id"
            cols="12"
            md="6"
            xs="12"
            sm="12"
          >
            <ArticleTableCellCard
              :articleObjects="item"
              @articleIDCallBack="articleIDCallBack"
            ></ArticleTableCellCard>
          </v-col>
        </v-row>
      </v-container>
      <!-- <input @click="getNextArticle" type="button" value="getNextArticle" /> -->

      <Breadcrumbs :breadCrumbsProps="breadCrumbsProps" />
    </v-container>
  </v-sheet>
</template>


<script>
import { mapState, mapActions } from "vuex";
import Constants from "@/uitls/Constants";
import Breadcrumbs from "@/components/Breadcrumbs";
import ArticleTableCellCard from "@/components/ArticleTableCellCard.vue";

export default {
  components: {
    Breadcrumbs,
    ArticleTableCellCard,
  },

  computed: {
    ...mapState({
      article: (state) => state.ArticleStore.article,
    }),

    breadCrumbsProps: function () {
      return {
        data: [
          { name: "mypage.topPage", to: "/" },
          { name: "homeview.new_topics" },
        ],
      };
    },
  },

  data() {
    return {
      list: [],
      selection: 0,
      page: 1,
      no_IMAGE: Constants.NO_IMAGE,
      articleCategories: null,
      searchKeyword: "",
    };
  },

  beforeMount() {},

  mounted() {
    this.getArticleList();
  },

  methods: {
    ...mapActions({
      getArticleData: "ArticleStore/getWordPressArticleList",
    }),

    articleIDCallBack(articleID) {
      this.$router.push({
        name: "ArticleViewDetail",
        params: { id: articleID },
      });
    },

    async categoryOnClick(categoryID, index) {
      this.selection = index;

      if (this.selection == 0) {
        this.getArticleList();
      } else {
        const categoryList = await this.$store.dispatch(
          "ArticleStore/getWordPressCategoryList",
          {
            categories: categoryID,
            _embed: true,
          }
        );

        this.list = null;
        this.list = categoryList;
      }
    },

    async getArticleList() {
      this.list = null;

      await this.getArticleData()
        .then(
          () => {
            this.list = this.article;
          },
          (error) => {
            console.error("Nothing from server." + error);
            this.$router.push("/404");
          }
        )
        .catch((e) => {
          console.error("error: ", e);
          this.$router.push("/404");
        });

      const array1 = [
        {
          id: 0,
          name: "all",
        },
      ];

      const array2 = await this.getWordPressCategories({
        per_page: 100,
      });

      this.articleCategories = array1.concat(array2);
    },

    getWordPressCategories(params) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("ArticleStore/getWordPressCategories", params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            throw new Error(error);
          });
      });
    },

    startSearchOnClick() {
      this.$router.push({
        name: "ArticleSearch",
        params: { keyword: this.searchKeyword },
      });
    },

    // getNextArticle() {
    //   window.onscroll = () => {
    //     const scrollPosition = window.scrollY + window.innerHeight;
    //     let bottomOfWindow = scrollPosition >= document.body.offsetHeight - 50;
    //     if (bottomOfWindow) {
    //       this.page = this.page + 1;
    //       ArticleStore.dispatch("getStory", this.page).then(
    //         (response) => {
    //           if (ArticleStore.state.property.list.length > 0) {
    //             this.list.push.apply(ArticleStore.state.property.list);
    //           }
    //         },
    //         (error) => {
    //           console.error("Nothing from server.");
    //         }
    //       );
    //     }
    //   };
    // },
  },
};
</script>

<style scoped>
.text-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
}
</style>