<!-- https://maps.multisoup.co.jp/blog/5146/ -->
<template>
  <v-sheet
    id="map"
    :light="true"
    style="width: 100%; height: 500px"
    class="pa-0 ma-0 mx-auto"
  />
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";

export default {
  props: ["address"],

  data: function () {
    return {
      map: null,
      google: null,
      marker: null,
      searchText: this.address,
    };
  },

  methods: {
    /**
     * 住所検索
     *
     * @param {String} text
     * @returns なし
     */
    addressSearch() {
      const vm = this;
      const geocoder = new this.google.maps.Geocoder();
      if (geocoder && this.searchText) {
        geocoder.geocode(
          { address: this.searchText },
          function (results, status) {
            if (vm.marker) {
              vm.marker.setMap(null);
            }
            if (status === vm.google.maps.GeocoderStatus.OK) {
              vm.marker = new vm.google.maps.Marker({
                map: vm.map,
                position: results[0].geometry.location,
                draggable: false,
              });
              vm.map.setCenter(results[0].geometry.location);
            }
          }
        );
      }
    },
  },

  mounted: function () {
    new Loader({
      apiKey: "AIzaSyAvTxz-6HZtoMMdBkFKXOcJtMsiFM11mzc",
      version: "frozen",
      libraries: ["places", "drawing", "geometry", "visualization"],
      language: "en",
    })
      .load()
      .then((google) => {
        this.google = google;

        // 地図の初期化
        this.map = new google.maps.Map(document.getElementById("map"), {
          // 初期表示設定
          zoom: 17,
          center: null,
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          streetViewControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM,
          },
          zoomControl: true,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM,
          },
          scaleControl: true,
        });

        // こちらにレスポンスとして受け取ったgoogleやthis.mapを使用すれば、
        // 通常通りvueでもJavaScriptAPIを利用できます。

        if (this.searchText.length === "null") {
          console.log("Empty address");
        } else {
          this.addressSearch();
        }
      })
      .catch((e) => {
        console.error(e);
      });
  },

  created() {
    console.log("created");
  },
};
</script>

<style></style>