<template>
  <v-sheet class="pa-0 ma-0 mx-auto text-center" color="white" max-width="1200">
    <p class="text-h6 mt-6" style="font-weight: bold">
      {{ $t("loginpage.sign_deals") }}
    </p>

    <v-card elevation="0" class="mt-6">
      <v-tabs fixed-tabs v-model="tab" color="blue" align-tabs="center">
        <v-tab value="one"> {{ $t("loginpage.have_ac") }}</v-tab>
        <v-tab value="two"> {{ $t("loginpage.new_ac") }}</v-tab>
      </v-tabs>
      <v-card-text>
        <v-window v-model="tab">
          <v-window-item value="one">
            <v-container fluid class="ma-4">
              <v-form v-model="formValid" @submit.prevent="onSubmit">
                <v-responsive class="mx-auto" max-width="600">
                  <v-text-field
                    hide-details="auto"
                    v-model="email"
                    variant="underlined"
                    type="email"
                    :label="$t('loginpage.email')"
                    :readonly="loading"
                    clearable
                    :placeholder="$t('loginpage.your_email')"
                    prepend-icon="mdi-email"
                    :error-messages="v$.email.$errors.map((e) => e.$message)"
                    @input="v$.email.$touch"
                    @blur="v$.email.$touch"
                  ></v-text-field>

                  <v-text-field
                    class="mt-6"
                    v-model="password"
                    clearable
                    :placeholder="$t('loginpage.your_password')"
                    :label="$t('loginpage.password')"
                    :hint="$t('loginpage.password_desc')"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    variant="underlined"
                    :readonly="loading"
                    prepend-icon="mdi-lock"
                    @click:append="showPassword = !showPassword"
                  >
                  </v-text-field>

                  <v-row>
                    <v-btn
                      variant="plain"
                      class="mt-4 mx-10 text-body-2 text-decoration-underline"
                      :ripple="false"
                      :to="{ name: 'ResetPassword' }"
                    >
                      {{ $t("loginpage.pass_forget") }}
                    </v-btn>
                  </v-row>
                  <br />
                  <v-checkbox
                    v-model="rememberPassword"
                    color="green"
                    :label="$t('loginpage.keep_sign')"
                  ></v-checkbox>
                  <br />
                  <p class="text-caption">
                    {{ $t("loginpage.agree1") }}
                    <a
                      :href="'/termsconditions'"
                      class="text-decoration-none text-info"
                    >
                      {{ $t("loginpage.tou") }}
                    </a>
                    {{ $t("loginpage.agree2") }}
                    <a
                      :href="'/privacypolicy'"
                      class="text-decoration-none text-info"
                    >
                      {{ $t("loginpage.privacy") }}
                    </a>
                    {{ $t("loginpage.statement") }}.
                  </p>

                  <v-btn
                    :ripple="false"
                    class="mt-2"
                    :disabled="!formValid"
                    :loading="loading"
                    block
                    color="success"
                    size="x-large"
                    type="submit"
                    variant="elevated"
                    @click="signInWithEmail"
                  >
                    {{ $t("loginpage.signin") }}
                  </v-btn>
                </v-responsive>
              </v-form>
            </v-container>
          </v-window-item>

          <v-window-item value="two">
            <v-container fluid class="ma-4">
              <v-form v-model="formValid" @submit.prevent="onSubmit">
                <v-responsive class="mx-auto" max-width="600">
                  <v-text-field
                    hide-details="auto"
                    :label="$t('loginpage.your_name')"
                    v-model="nameNew"
                    variant="underlined"
                    :readonly="loading"
                    clearable
                    :placeholder="$t('loginpage.your_name')"
                    prepend-icon="mdi-account"
                    :error-messages="v$.nameNew.$errors.map((e) => e.$message)"
                    @input="v$.nameNew.$touch"
                    @blur="v$.nameNew.$touch"
                  ></v-text-field>

                  <v-text-field
                    class="mt-6"
                    hide-details="auto"
                    v-model="emailNew"
                    clearable
                    type="email"
                    :label="$t('loginpage.email')"
                    variant="underlined"
                    :readonly="loading"
                    :placeholder="$t('loginpage.your_email')"
                    prepend-icon="mdi-email"
                    :error-messages="v$.emailNew.$errors.map((e) => e.$message)"
                    @input="v$.emailNew.$touch"
                    @blur="v$.emailNew.$touch"
                  ></v-text-field>

                  <v-text-field
                    class="mt-6"
                    clearable
                    v-model="passwordNew"
                    :label="$t('loginpage.password')"
                    :hint="$t('loginpage.password_desc')"
                    persistent-hint
                    variant="underlined"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    prepend-icon="mdi-lock"
                    counter
                    @click:append="showPassword = !showPassword"
                    :error-messages="
                      v$.passwordNew.$errors.map((e) => e.$message)
                    "
                    @input="v$.passwordNew.$touch"
                    @blur="v$.passwordNew.$touch"
                  >
                  </v-text-field>
                  <br />
                  <br />
                  <p class="text-caption">
                    {{ $t("loginpage.agree1") }}
                    <a
                      :href="'/termsconditions'"
                      class="text-decoration-none text-info"
                    >
                      {{ $t("loginpage.tou") }}
                    </a>
                    {{ $t("loginpage.agree2") }}
                    <a
                      :href="'/privacypolicy'"
                      class="text-decoration-none text-info"
                    >
                      {{ $t("loginpage.privacy") }}
                    </a>
                    {{ $t("loginpage.statement") }}.
                  </p>

                  <v-btn
                    :ripple="false"
                    class="mt-2"
                    :disabled="!formValid"
                    :loading="loading"
                    block
                    color="success"
                    size="x-large"
                    type="submit"
                    variant="elevated"
                    @click="checkUserEmail"
                  >
                    {{ $t("loginpage.signup") }}
                  </v-btn>
                </v-responsive>
              </v-form>
            </v-container>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>

    <v-container>
      <div class="d-flex ma-0">
        <hr class="my-auto flex-grow-1 ma-6" />
        <p class="px-4 text-black text-h6 font-italic">or</p>
        <hr class="my-auto flex-grow-1 ma-6" />
      </div>

      <v-row justify="center" class="mt-6">
        <!-- <v-col cols="6">
          <v-btn
            :ripple="false"
            prepend-icon="mdi-phone"
            color="blue"
            size="x-large"
            width="100%"
          >
            Phone
          </v-btn>
        </v-col> -->

        <v-col cols="10">
          <v-btn
            :ripple="false"
            prepend-icon="mdi-google"
            color="green"
            size="x-large"
            width="100%"
            @click="GoogleSignIn"
            variant="outlined"
            block
          >
            Google
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <br />
  </v-sheet>

  <VSonner position="top-center" />
</template>

<script>
import { useHead } from "@vueuse/head";
import { mapState, mapActions, mapGetters } from "vuex";
import { VSonner, toast } from "vuetify-sonner";
import utils from "@/uitls/Uitls";
import { useVuelidate } from "@vuelidate/core";
import {
  email,
  required,
  sameAs,
  helpers,
  minLength,
  numeric,
  maxLength,
} from "@vuelidate/validators";

export default {
  components: {
    VSonner,
  },

  props: ["guest"],

  computed: {
    ...mapState({
      checkEmail: (state) => state.CheckUserEmailStore.property,
    }),
    ...mapGetters({
      isAuthenticated: "authStore/idToken",
    }),
  },

  watch: {
    isAuthenticated(to, from) {
      console.log("isAuthenticated");
    },
  },

  setup() {
    useHead({
      meta: [
        {
          name: "robots",
          content: "noindex,nofollow",
        },
      ],
    });

    return { v$: useVuelidate() };
  },

  data: () => ({
    tab: null,
    rememberPassword: false,
    formValid: false,
    nameNew: null,
    emailNew: null,
    passwordNew: null,
    email: null,
    password: null,
    loading: false,
    showPassword: false,
    checkEmailProperty: null,
  }),

  validations() {
    return {
      nameNew: {
        required,
        minLength: minLength(3),
      },
      passwordNew: {
        required,
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          return containsUppercase && containsLowercase && containsNumber;
        },
        minLength: minLength(6),
      },
      emailNew: {
        required,
        email,
      },
      email: {
        required,
        email,
      },
    };
  },

  mounted() {
    this.getCookieArray();
  },

  methods: {
    ...mapActions({
      parseData: "CheckUserEmailStore/getData",
    }),

    getCookieArray() {
      let arr = new Array();
      if (document.cookie != "") {
        let tmp = document.cookie.split("; ");
        for (let i = 0; i < tmp.length; i++) {
          let data = tmp[i].split("=");
          arr[data[0]] = decodeURIComponent(data[1]);
        }
        if (arr["email"] && arr["password"] != "") {
          this.rememberPassword = true;
          this.email = arr["email"];
          this.password = arr["password"];
        }
      }
    },

    onSubmit() {
      if (!this.formValid) return;
    },
    required(v) {
      return !!v || "Field is required";
    },

    async signInAnonymously() {
      await this.$store
        .dispatch("authStore/signInAnonymously")
        .then((res) => {
          console.log(res);
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else {
            this.$router.push({ name: "HomeView" });
          }
        })
        .catch((err) => {
          toast(err, {
            cardProps: {
              color: "error",
              class: "my-toast",
            },
          });
        });
    },

    async checkUserEmail() {
      if (!this.emailNew || !this.passwordNew || !this.nameNew) return;

      this.loading = true;
      await this.parseData(this.emailNew)
        .then(
          () => {
            this.loading = false;
            this.checkEmailProperty = this.checkEmail;
            if (this.checkEmailProperty.result === 1) {
              toast(this.$i18n.tc("loginpage.pls_login"), {
                cardProps: {
                  color: "error",
                  class: "my-toast",
                },
              });
            } else {
              this.signUpNewUser();
            }
          },
          (error) => {
            this.loading = false;
            toast(this.$i18n.tc("loginpage.pls_check_mail_format"), {
              cardProps: {
                color: "error",
                class: "my-toast",
              },
            });
            console.error(error);
          }
        )
        .catch((e) => {
          this.loading = false;
          console.error("error: ", e);
        });
    },

    async signUpNewUser() {
      this.loading = true;
      const account = {};
      account.email = this.emailNew;
      account.password = this.passwordNew;

      await this.$store
        .dispatch("authStore/signUp", account)
        .then((res) => {
          this.loading = false;
          console.log(res.user);
          res.user.updateProfile({
            displayName: this.nameNew,
          });

          this.sendEmailVerification(this.emailNew);
        })
        .catch((err) => {
          this.loading = false;
          toast(err, {
            cardProps: {
              color: "error",
              class: "my-toast",
            },
          });
        });
    },

    async sendEmailVerification(value) {
      await this.$store
        .dispatch("authStore/sendEmailVerification")
        .then((res) => {
          this.$router.push({
            name: "VerificationEmail",
            params: { user_email: value },
          });
        })

        .catch((err) => {
          toast(err, {
            cardProps: {
              color: "error",
              class: "my-toast",
            },
          });
        });
    },

    async signInWithEmail() {
      if (!this.email) return;

      this.loading = true;
      const account = {};
      account.email = this.email;
      account.password = this.password;

      await this.$store
        .dispatch("authStore/signInWithEmail", account)
        .then((res) => {
          // Firebaseログイン成功
          if (!res.user.emailVerified) {
            this.loading = false;
            // Firebase上のデータでメール確認が終わっていない場合
            this.sendEmailVerification(this.email);
          } else {
            this.getUserProfile();

            if (this.rememberPassword) {
              document.cookie = "email=" + this.email;
              document.cookie = "password=" + this.password;
            } else {
              //保存しない場合
              //クッキーを削除
              document.cookie = "email=; expires=0";
              document.cookie = "password=; expires=0";
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          toast(this.$i18n.tc("loginpage.check_mail_pass"), {
            cardProps: {
              color: "error",
              class: "my-toast",
            },
          });
        });
    },

    async GoogleSignIn() {
      await this.$store
        .dispatch("authStore/signInWithGoogle")
        .then((res) => {
          this.getUserProfile();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getUserProfile() {
      if (!this.isAuthenticated) {
        this.$store
          .dispatch("authStore/checkUserLoginState")
          .then((res) => {
            this.parseUserInfo();
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        this.parseUserInfo();
      }
    },

    async parseUserInfo() {
      const params = {
        id_token: this.isAuthenticated,
      };
      await this.$store
        .dispatch("authStore/loginWithIdToken2", params)
        .then((res) => {
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else {
            this.$router.push({ name: "MyPage" });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style scoped>
</style>